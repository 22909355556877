import { ArrowBack, Edit, MoreVert } from '@mui/icons-material';
import { Box, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import AppButton from '../forms/AppButton';
import { useNavigate } from 'react-router-dom';
import { ROUTE_ACTIONS } from '../../constants/routes';
import { LayoutState } from '../../state/reducers/layout.reducer';
import { useSelector } from 'react-redux';

export interface AppDetailPageHeaderProps {
  title: string;
  action?: string;
  editRoute?: string;
}


const AppDetailPageHeader: React.FC<AppDetailPageHeaderProps> = ({ action, editRoute, title }) => {

  const navigate = useNavigate();
  const [mode, setMode] = React.useState(ROUTE_ACTIONS.VIEW);
  const layout = useSelector((state: any) => state.layout as LayoutState);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
      setAnchorEl(null);
  };

  React.useEffect(() => {
    if (action) {
      switch (action) {
        case ROUTE_ACTIONS.EDIT:
          setMode("Edição");
          break;
        case ROUTE_ACTIONS.VIEW:
          setMode("Visualização");
          break;
        default:
          setMode("Visualização");
      }
    }
  }, [action]);


  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexGrow: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 1 }}>
        <IconButton onClick={() => { navigate(-1) }}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" component="h2" sx={{ fontWeight: "bold", mr: 2 }}>
          {title} &nbsp;
          {layout.windowSize.isMobile && <br />}
          <Typography component={"span"} sx={{ color: "#ccc" }}>Modo {mode}</Typography>
        </Typography>
      </Box>

      {(action === ROUTE_ACTIONS.VIEW && editRoute && !layout.windowSize.isMobile) &&
        <Box>
          <AppButton startIcon={<Edit />} variant="contained" color="primary" onClick={() => { navigate(editRoute) }} label='Editar'></AppButton>
        </Box>
      }

      { (action === ROUTE_ACTIONS.VIEW && layout.windowSize.isMobile && editRoute) &&
        <Tooltip title="Ações">
          <IconButton
            onClick={handleClick}
            sx={{}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVert />
          </IconButton>
        </Tooltip>
      }

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <>
          <MenuItem onClick={() => {
            handleClose()
            navigate(editRoute!);
          }}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <strong>Editar</strong>
          </MenuItem>
        </>
      </Menu>


    </Box>
  );
}

export default AppDetailPageHeader;