import { Box, Breadcrumbs, Divider, Link, Typography, Avatar } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React, { useCallback, useEffect, useState } from "react";
import AppSearchIcon from "../icons/AppSearchIcon";
import AppTextField from "../forms/AppTextField";
import { useAuth } from "../../hooks/core/AuthProvider";
import { useSelector } from "react-redux";
import ExitToApp from '@mui/icons-material/ExitToApp';
import { useLocation, useNavigate } from "react-router-dom";
import { LayoutState } from "../../state/reducers/layout.reducer";
import { ROUTES } from "../../constants/routes";
import AppManageAccountIcon from "../icons/AppManageAccountIcon";
import { usePatientStore } from "../../pages/MyPatients/Stores/PatientStore/PatientStore";

export interface AppMainHeaderProps {
  hasBreadCrumbs: boolean;
}

export interface LinkBreadCrumbs {
  href: string;
  label: string;
  isActive?: boolean;
}

export interface BreadCrumbsConfigurations {
  [key: string]: any;
}

const MainSearchArea = () => {
  return (
    <Box>
      <AppTextField
        sx={{
          backgroundColor: "#f5f5f5",
          "&:hover": {
            outline: "none",
            borderColor: "red",
          },
        }}
        name="search"
        type="text"
        autoFocus={false}
        startAdornment={<AppSearchIcon sx={{ mr: 0 }} />}
        placeholder="Pesquisar funcionalidades"
        fullWidth={false}
      />
    </Box>
  );
};

const MenuItem = ({ icon, text, onClick }: { icon: React.ReactNode, text: string, onClick: () => void }) => {
  return (
    <Typography sx={{
      cursor: "pointer", display: "flex", alignItems: "center", gap: 1, transition: ".3s", ":hover": {
        color: "#444",
      }
    }} component="p" onClick={onClick}>
      {icon}
      {text}
    </Typography>
  )
}

const AppMainHeader: React.FC<AppMainHeaderProps> = (props) => {
  const { hasBreadCrumbs } = props;
  const { user, logout } = useAuth();
  const location = useLocation();
  const [linksBreadCrumbs, setLinksBreadCrumbs] = useState([] as LinkBreadCrumbs[]);
  const { pathname } = location;
  const { patient } = usePatientStore();

  const layout = useSelector((state: any) => state.layout as LayoutState);
  const [showMenu, setShowMenu] = React.useState(false);
  const navigate = useNavigate();

 

  const getFirstName = (name: string) => {
    const firstName = name.split(" ")[0];
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  }

  const getBreadCrumbs = useCallback(() => {
    const breadCrumbs = {
      'meus-pacientes': {
        "meus-pacientes": "Meus Pacientes",
        "visualizar": patient?.name ?? "Paciente",
        "nova-anamnese": "Nova Anamnese",
        "nova-antropometria": "Nova Antropometria",
        "anamnese": "Anamnese",
        "antropometria": "Antropometria"
      },
      "meus-consultorios": {
        "meus-consultorios": "Meus Consultórios",
        "visualizar": "Consultório",
        "editar": "Edição"
      }
    } as BreadCrumbsConfigurations;


    if (!hasBreadCrumbs) return;
  
    var paths = pathname.split('/').slice(1, 4);
    var endPath = pathname.split('/').slice(-1)[0];
  
    const labels = breadCrumbs[paths[0]];
  
    const updatedLinks = [] as LinkBreadCrumbs[];
  
    let path = '';
  
    for (let i = 0; i < paths.length; i++) {
      let label = labels[paths[i]];
      path += `/${paths[i]}`;
  
      if (i === 1) {
        label = labels['visualizar'];
        updatedLinks.push({ label, href: `${path}/visualizar`, isActive: endPath === 'visualizar' });
  
        if (endPath === 'visualizar') break;
  
        continue;
      }

      if(i === (paths.length -1)) {
        updatedLinks.push({ label, href: "#", isActive: path === pathname });
        continue;
      }
  
      updatedLinks.push({ label, href: path, isActive: path === pathname });
    };
  
    setLinksBreadCrumbs(updatedLinks);
  }, [hasBreadCrumbs, pathname, patient?.name]);

  useEffect(() => {
    getBreadCrumbs();
  }, [patient?.name, getBreadCrumbs])


  const profileImageSource = user.profileImage ? `${process.env.REACT_APP_API_URL}/health/profile-image?nameFile=${user.profileImage}` : undefined;

  return (
    <>
      <Box
        sx={{
          display: layout.windowSize.width <= 600 ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Typography variant="h3" component="h1">
            Olá, {getFirstName(user.name)}!
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{ mt: 2, color: "#666" }}
          >
            Seja bem-vindo(a) à área Nutrifit!
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            height: "42px",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
          }}
        >
          <MainSearchArea />
          <Divider orientation="vertical" flexItem />
          <Avatar sx={{border:"2px solid orange" }} src={profileImageSource}
            onClick={() => setShowMenu(!showMenu)}
            onMouseOver={() => setShowMenu(true)}> 
            <AccountCircleIcon sx={{ fontSize: "42px", color: "#999", cursor: "pointer" }} />
          </Avatar> 
          <Box onMouseLeave={() => { setShowMenu(false) }} sx={{ transition: ".3s", position: 'absolute', right: 0, top: 0, zIndex: 1000, backgroundColor: '#fff', p: 3, boxShadow: "0px 0px 15px #ccc", mt: 6, opacity: showMenu ? '1' : '0', borderRadius: 2 }}>

            <MenuItem icon={<AppManageAccountIcon />} text="Meu Perfil" onClick={() => navigate(ROUTES.MY_PROFILE)} />
            <Divider sx={{ my: 2 }} />
            <MenuItem icon={<ExitToApp />} text="Sair" onClick={logout} />

          </Box>
        </Box>
      </Box>
      {hasBreadCrumbs &&
        <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 2, backgroundColor:"#fff", display:"inline-block", p: 1, pl:2, pr:2, borderRadius:2, border:"1px solid #ccc" }}>

          {linksBreadCrumbs &&
            linksBreadCrumbs.map((link, index) => {
              return (
                <Link
                  key={`breadcrumb-${index}`}
                  underline="hover"
                  color="inherit"
                  href={link.href}
                  sx={{fontFamily: "Comfortaa"}}
                >
                  {link.isActive ? <strong>{link.label}</strong> : link.label}
                </Link>)
            })

          }
        </Breadcrumbs>
      }
    </>

  );
}

export default AppMainHeader;