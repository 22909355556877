import React, { ChangeEvent, useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import AppMainLayout from "../../components/layout/AppMainLayout";
import AppModal from "../../components/layout/AppModal";
import AppMainHeader from "../../components/layout/AppMainHeader";
import AppCard from "../../components/layout/AppCard";
import AppTable, { AppTableColumn, AppTableRowData } from "../../components/layout/AppTable/AppTable";
import AppButton from "../../components/forms/AppButton";
import NewClinicForm from "./components/NewClinicForm";
import { useAuth } from "../../hooks/core/AuthProvider";
import { useAppToast } from '../../hooks/core/AppToastContextProvider';
import { useDialog } from "../../hooks/core/AppDialog/AppDialogProvider";

import { Clinic } from "../../domain/interfaces/Clinic";
import api from "../../services/api";
import AppTableCrudActions, { AppTableCrudActionsEnum } from "../../components/layout/AppTable/AppTableCrudActions";
import AppLoading from "../../components/utils/AppLoading";
import FormService, { FormErrors } from "../../services/form.service";
import AppErrorList from "../../components/forms/AppErrorList";

const MyClinicsPage: React.FC = () => {
  const [modalNewClinicIsOpen, setModalNewClinicIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [clinics, setClinics] = React.useState([] as AppTableRowData[]);
  const { user } = useAuth();
  const { addToast } = useAppToast();
  const dialog = useDialog();

  const [errors, setErrors] = useState([] as string[]);
  const [formErrors, setFormErrors] = useState({} as FormErrors);

  const formService = new FormService(clinics, setClinics, setErrors, setFormErrors);


  const columns: AppTableColumn[] = [
    { prop: 'name', label: 'Nome', maxWidth: "300px" },
    { prop: 'description', label: 'Descrição' },
    { prop: 'actions', label: 'Ações', align: 'left', maxWidth: "150px" }
  ];

  const handleDeleteClinic = useCallback(async (clinic: Clinic) => {
    setErrors([]);
    const isConfirmed = await dialog.confirm({
      title: "Apagar consultório",
      confirmActionText: "Apagar",
      message: `Você tem certeza que deseja apagar o consultório <b>${clinic.name}</b>? </br> Se houver pacientes vinculados a este consultório, eles perderão automaticamente essa vinculação e ficarão sem um consultório associado.`
    });

    if (isConfirmed) {
      try {
        setIsProcessing(true);

        const response = await api.delete(`/nutritionists/${user?.id}/clinics/${clinic.id}`);

        setIsProcessing(false);
        if (response.status === 200) {
          await getMyClinics();
          addToast({
            title: "Consultório Apagado",
            description: "Consultório apagado com sucesso.",
            type: "success"
          });
          return;
        }
      } catch (errors) {
        setIsProcessing(false);
        const messagesError = formService.getErrors(errors);
        setErrors(messagesError);

        addToast({
          title: "Erro ao apagar consultório",
          description: "Ocorreu um erro ao apagar o consultório, tente novamente.",
          type: "error"
        });
      }
    }
  }, [addToast, dialog, user?.id])

  const getMyClinics = useCallback(async () => {
    setErrors([]);
    const response = await api.get(`/nutritionists/${user?.id}/clinics`);
    if (response.status === 204) {
      setClinics([]);
    }

    if (response.status === 200) {
      const clinicsList = response.data as Clinic[];
      const clinicsData = clinicsList.map(clinic => {
        return {
          name: { value: clinic.name },
          description: { value: clinic.description },
          actions: {
            value: clinic.name, render: (value) => {
              return (
                <AppTableCrudActions
                  actions={[
                    { action: AppTableCrudActionsEnum.VIEW, title: "Visualizar Consultório" },
                    { action: AppTableCrudActionsEnum.EDIT, title: "Editar Consultório" },
                    { action: AppTableCrudActionsEnum.DELETE, title: "Apagar Consultório", onClick: handleDeleteClinic }
                  ]}
                  data={clinic}
                  basePath={`/meus-consultorios`}
                />
              );
            }
          }
        } as AppTableRowData;
      });

      setClinics(clinicsData);
    }
  }, [handleDeleteClinic, user?.id]);

  const onSubmit = useCallback(async (clinic: Clinic) => {
    setErrors([]);
    const nutritionistId = user?.id;
    try {
      setIsProcessing(true);
      const response = await api.post(`/nutritionists/${nutritionistId}/clinics`, clinic);

      if (response.status === 201) {
        await getMyClinics();
        setModalNewClinicIsOpen(false);
        addToast({
          title: "Consultório Adicionado",
          description: "Consultório adicionado com sucesso!",
          type: "success"
        });
      }

    } catch (errors) {
      addToast({
        title: "Erro ao adicionar consultório",
        description: "Ocorreu um erro ao adicionar o consultório, tente novamente!",
        type: "error"
      });
    }
    setIsProcessing(false);
  }, [addToast, getMyClinics, user?.id]);


  React.useEffect(() => {
    getMyClinics();
  }, [user?.id, getMyClinics]);

  return (
    <AppMainLayout>
      <AppMainHeader hasBreadCrumbs={false} />
      <AppLoading isLoading={isProcessing} />

      <AppCard>
        <Typography variant="h5" component="h2" sx={{ fontWeight: "bold" }}>
          Meus Consultórios
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", mb: 2, mt: 2 }}>

          <AppButton
            variant="contained"
            startIcon={<Add />}
            label="Adicionar Consultório"
            fullWidth={false}
            onClick={() => setModalNewClinicIsOpen(true)}
          />
        </Box>
        <AppErrorList errors={errors} />
        <AppTable rows={clinics} columns={columns} />

      </AppCard>

      <AppModal isOpen={modalNewClinicIsOpen} handleClose={() => setModalNewClinicIsOpen(false)} title="Novo Consultório">
        <NewClinicForm isProcessing={isProcessing} onSubmit={onSubmit} />
      </AppModal>
    </AppMainLayout>
  );
};

export default MyClinicsPage;
