import React, {FC} from "react";
import AppMainHeader from "../../../../components/layout/AppMainHeader";
import AppCard from "../../../../components/layout/AppCard";
import AppMainLayout from "../../../../components/layout/AppMainLayout";
import {Box, Divider, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import AppTextField from "../../../../components/forms/AppTextField";
import {LabelType} from "../../../../components/forms/enums/LabelType";
import AppSelectable from "../../../../components/forms/AppSelectable";
import {
    ArrowBack,
    Bookmark,
    BookmarkAdd,
    ReportOff,
    Save,
    Share,
    Visibility,
} from "@mui/icons-material";
import AppInterationsPageHeader, {
    AppInterationHeaderAction,
} from "../components/AppInterationsPageHeader";
import AddIcon from "@mui/icons-material/Add";
import {v4 as uuidv4} from "uuid";
import {MealBox} from "./components/MealBox";
import {AddItemArea} from "./components/AddItemArea";
import NewDietPlanManual from "./NewDietPlanManual";
import NewDietPlanImport from "./NewDietPlanImport";
import AppButton from "../../../../components/forms/AppButton";
import {useLocation, useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../constants/routes";

const headerActions: AppInterationHeaderAction[] = [
    {
        label: "Salvar",
        featured: true,
        icon: <Save/>,
        onClick: () => {
        },
    },
    {
        label: "Salvar como modelo",
        showAsIconButton: true,
        featured: true,
        icon: <BookmarkAdd/>,
        onClick: () => {
        },
    },
    {
        label: "Compartilhar",
        featured: true,
        showAsIconButton: true,
        separated: true,
        icon: <Share/>,
        onClick: () => {
        },
    },
    {
        label: "Visualizar",
        icon: <Visibility/>,
        onClick: () => {
        },
    },
    {
        label: "Carregar Modelo",
        icon: <Bookmark/>,
        onClick: () => {
        },
    },
    {
        label: "Inativar Plano",
        separated: true,
        icon: <ReportOff/>,
        onClick: () => {
        },
    },
];

interface ModeOptionProps {
    title: string;
    subtitle: string;
    actionText: string;
    onClick: () => void;
}

const ModeOption: FC<ModeOptionProps> = (props) => {
    return <Box sx={{textAlign: "center", p:2}}>
        <Typography variant={"h5"} sx={{mb: 2}}>{props.title}</Typography>
        <Typography variant={"body2"} sx={{minHeight:"60px"}}>{props.subtitle}</Typography>
        <AppButton sx={{mt: 2}} startIcon={<AddIcon/>}
                   variant={"contained"}
                   color={"secondary"}
                   label={props.actionText}
                   onClick={props.onClick}
        />
    </Box>;
}

const DietPlanPage: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();


    const path = location.pathname;

    // Divide a URL e pega a última parte
    const mode = path.split('/').filter(Boolean).pop() as "novo-plano-alimentar" | "manual" | "template";

    return (
        <AppMainLayout>
            <AppMainHeader hasBreadCrumbs={true}/>
            <AppCard cutPaddingTop={mode !== "novo-plano-alimentar"}>
                {mode !== "novo-plano-alimentar" && <AppInterationsPageHeader title={"Novo plano alimentar"} actions={headerActions}/> }

                {mode === "novo-plano-alimentar" && (
                    <>
                        <Box sx={{ mt:2, display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <IconButton sx={{}} onClick={()=>navigate(-1)}>
                                <ArrowBack />
                            </IconButton>
                            <Typography textAlign={"center"} variant={"h4"}>
                                Como você deseja criar um novo plano alimentar?
                            </Typography>
                            <Box>

                            </Box>
                        </Box>

                        <Grid container justifyContent={"center"} sx={{mt: 2, p:2}}>
                            <Grid item xs={12} md={5}>
                                <ModeOption
                                    title={"Manualmente"}
                                    subtitle={"Nesse modo você vai precisar criar as refeições do zero e selecionar os dias da semana aos quais o plano se aplica."}
                                    actionText={"Criar do zero"}
                                    onClick={() => navigate("./manual")}
                                />
                            </Grid>
                            <Divider flexItem orientation="horizontal" sx={{borderWidth: "1px!important"}}/>
                            <Grid item xs={12} md={5}>
                                <ModeOption
                                    title={"De um modelo"}
                                    subtitle={"Nesse modo você poderá escolher um modelo de plano alimentar completo e ajustar as refeições e dias da semana conforme necessário."}
                                    actionText={"Importar modelo"}
                                    onClick={() => navigate("./template")}
                                    />

                            </Grid>

                        </Grid>
                    </>
                )}

                {mode === "manual" && (
                    <NewDietPlanManual/>
                )}

                {mode === "template" && (
                    <NewDietPlanImport/>
                )}
            </AppCard>
        </AppMainLayout>
    );
};

export default DietPlanPage;