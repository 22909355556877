import { Avatar, Box, Divider, Fab, Grid, Typography } from "@mui/material";
import CameraAlt from "@mui/icons-material/CameraAlt";
import React, { FormEvent, useEffect, useState, ChangeEvent, useRef } from "react";
import { Delete, Save } from "@mui/icons-material";
import RefreshIcon from '@mui/icons-material/Refresh';
import logo from '../../assets/img/nutrifit-logo.png';
import * as Yup from 'yup';
import api from "../../services/api";
import FormService, { FormErrors } from "../../services/form.service";
import { Nutritionist } from "../../domain/interfaces/Nutritionist";
import { useAppToast } from "../../hooks/core/AppToastContextProvider";
import { useAuth, User } from "../../hooks/core/AuthProvider";
import AppTextField from "../../components/forms/AppTextField";
import AppErrorList from "../../components/forms/AppErrorList";
import AppMaskedTextField from "../../components/forms/AppMaskedTextField";
import AppButton from "../../components/forms/AppButton";
import AppMainLayout from "../../components/layout/AppMainLayout";
import AppMainHeader from "../../components/layout/AppMainHeader";
import AppLoading from "../../components/utils/AppLoading";
import AppCard from "../../components/layout/AppCard";
import AppRichText from "../../components/forms/AppRichText";
import { useDialog } from "../../hooks/core/AppDialog/AppDialogProvider";
import { useSelector } from "react-redux";
import { LayoutState } from "../../state/reducers/layout.reducer";

const baseUrlImage = `${process.env.REACT_APP_API_URL}/health/profile-image?nameFile=`;

const formSchema = Yup.object({
    name: Yup
        .string()
        .required('Obrigatório informar o nome.')
        .max(100, 'O campo nome deve ter no máximo 100 caracteres'),
    crn: Yup
        .string()
        .nullable()
        .max(50, 'O campo CRN deve ter no máximo 50 caracteres'),
    phoneNumber: Yup
        .string()
        .required('Obrigatório informar o telefone.')
        .min(10, 'O telefone deve ter no mínimo 10 caracteres.')
        .max(20, 'O telefone deve ter no máximo 20 caracteres.'),
    document: Yup
        .object()
        .required("Obrigatório informar o Documento."),
});


const MyProfilePage: React.FC = () => {
    const { user, updateUser } = useAuth();
    const [nutritionist, setNutritionist] = useState({} as Nutritionist);
    const { addToast } = useAppToast();
    const dialog = useDialog();

    const [formErrors, setFormErrors] = useState({} as FormErrors);
    const [errors, setErrors] = useState([] as string[]);
    const [isLoading, setIsLoading] = useState(true);
    const profileImageInputRef = useRef<HTMLInputElement>(null);
    const logoInputRef = useRef<HTMLInputElement>(null);

    const [urlLogo, setUrlLogo] = useState(null as string | null);
    const [urlProfileImage, setUrlProfileImage] = useState(null as string | null);

    const formService = new FormService(nutritionist, setNutritionist, setErrors, setFormErrors);
    const layout = useSelector((state: any) => state.layout as LayoutState);
    const [isMobile] = useState<boolean>(layout.windowSize.isMobile || layout.windowSize.isTablet);

    // TODO: Acredito que essa implementação deve ser feita no BACKEND
    const getDefaultSignature = (nutritionist: Nutritionist): string => {
        let signatureText = `<h3>${nutritionist.name}</h3><h5><br></h5>` +
            `<h5><strong>E-mail:</strong> ${nutritionist.email}</h5>`;

        signatureText += nutritionist.crn !== null && nutritionist.crn !== undefined ? `<h5><strong>CRN:</strong>${nutritionist.crn}</h5>` : ``;

        return signatureText;
    };

    const getNutritionist = async () => {
        setIsLoading(true);
        const response = await api.get(`/nutritionists/${user?.id}`);

        if (response.status === 200) {
            let nutritionistApi = response.data as Nutritionist;

            if (nutritionistApi.signatureText == null) {
                const signatureText = getDefaultSignature(nutritionistApi);
                nutritionistApi = { ...nutritionistApi, signatureText };
            }

            setUrlLogo(nutritionistApi.logoImage);
            setUrlProfileImage(nutritionistApi.profileImage);

            setNutritionist(nutritionistApi);
            setIsLoading(false);

            return;
        }

        setIsLoading(false);
        addToast({ title: "Erro", description: "Erro ao buscar dados do nutricionista", type: "error" });
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        formService.cleanErrors();

        try {
            await formSchema.validate(nutritionist, { abortEarly: false });

            const response = await api.put(`/nutritionists/${user?.id}`, nutritionist);
            if (response.status !== 200) {
                throw response.data;
            }

            const userUpdated = {
                ...user,
                name: nutritionist.name,
                phone: nutritionist.phoneNumber
            } as User;

            updateUser(userUpdated);

            addToast({
                title: "Atualização Realizada",
                description: "Dados Atualizados com Sucesso.",
                type: "success"
            });

        } catch (err) {
            formService.handleErros(err);
        }

        setIsLoading(false);
    }

    const handleProfileImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const data = new FormData();
            var file = e.target.files[0];
            data.append('profileImage', file);

            setIsLoading(true);
            let response = await api.patch(`/nutritionists/${user.id}/profile-image`, data);
            setIsLoading(false);

            if (response.status === 200) {
                const profileImage = `${response.data.profileImage}&t=${new Date().getTime()}`;

                setNutritionist({
                    ...response.data,
                    profileImage: profileImage
                });

                setUrlProfileImage(profileImage);

                updateUser({
                    ...user,
                    profileImage: profileImage
                });

                addToast({
                    type: 'success',
                    title: 'Avatar atualizado com sucesso.',
                });

                return;
            }

            addToast({
                type: 'error',
                title: 'Houve uma falha na atualização da foto do perfil. Verifique!',
            });
        }
    };

    const handleLogoChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const data = new FormData();
            var file = e.target.files[0];
            data.append('logoImage', file);

            setIsLoading(true);
            let response = await api.patch(`/nutritionists/${user.id}/logo-image`, data);
            setIsLoading(false);

            if (response.status === 200) {
                const logoImage = `${response.data.logoImage}&t=${new Date().getTime()}`;

                setNutritionist({
                    ...response.data,
                    logoImage: logoImage
                });

                setUrlLogo(logoImage);

                addToast({
                    type: 'success',
                    title: 'Logo atualizada com sucesso.',
                });

                return;
            }

            addToast({
                type: 'error',
                title: 'Houve uma falha na atualização da logo. Verifique!',
            });
        }
    };

    const handleRemoveLogo = async (event: FormEvent<HTMLButtonElement>) => {
        let isConfirmed = await dialog.confirm({
            message: "Tem certeza que deseja remover a logo?",
            title: "Remover Logo",
            confirmActionText: "Apagar"
        });

        if (!isConfirmed) return;

        setIsLoading(true);
        formService.cleanErrors();

        try {
            var updatedNutritionist = { ...nutritionist, logoImage: null };
            const response = await api.put(`/nutritionists/${user?.id}`, updatedNutritionist);
            if (response.status !== 200) {
                throw response.data;
            }

            setUrlLogo(null);

            addToast({
                title: "Logo removida com sucesso.",
                type: "success"
            });

        } catch (err) {
            formService.handleErros(err);
        }

        setIsLoading(false);
    }

    const handleSignatureText = (value: string): void => {
        setNutritionist({ ...nutritionist, signatureText: value });
    };

    const handleProfileImageButtonClick = () => {
        if (profileImageInputRef)
            profileImageInputRef.current?.click();
    };

    const handleLogoButtonClick = () => {
        if (logoInputRef)
            logoInputRef.current?.click();
    };

    useEffect(() => {
        getNutritionist();
    }, [])

    return (
        <AppMainLayout>
            <AppLoading isLoading={isLoading} />
            <AppMainHeader hasBreadCrumbs={false} />
            <AppCard >
                <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                    <Avatar
                        alt="Remy Sharp"
                        src={urlProfileImage !== null ? `${baseUrlImage}${urlProfileImage}` : ''}
                        sx={{ width: 160, height: 160, border: "solid 3px #D9D9D9" }}
                    />
                    <Fab color="primary" size="small" aria-label="edit" onClick={handleProfileImageButtonClick}
                        sx={{
                            position: "absolute",
                            ml: "115px",
                            zIndex: 1,
                        }} >
                        <CameraAlt />
                    </Fab>
                    <input accept=".jpg,.jpeg,.png" ref={profileImageInputRef} type="file" onChange={handleProfileImageChange} style={{ display: "none" }} />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: "flex", width: "100%", mt: 4, justifyContent: "center" }}>
                        <Typography variant="h5" component="h3" sx={{ textAlign: "center", fontWeight: "bold" }}>Dados Cadastrais</Typography>
                    </Box>
                    <Divider sx={{ mt: 1, mb: 4 }} />
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <AppTextField
                                    fullWidth
                                    name="name"
                                    type="text"
                                    autoFocus={true}
                                    label="Nome Completo"
                                    value={nutritionist.name}
                                    onChange={(e) => formService.setInputValue("name", e.target.value)}
                                    errorMessage={formErrors.name}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <AppTextField
                                    fullWidth
                                    name="email"
                                    disabled
                                    type="text"
                                    autoFocus={true}
                                    label="E-mail"
                                    value={nutritionist.email}
                                    onChange={(e) => formService.setInputValue("email", e.target.value)}
                                    errorMessage={formErrors.email}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
                            <Grid item xs={12} md={3}>
                                <AppMaskedTextField
                                    id="document"
                                    fullWidth
                                    mask="999.999.999-99"
                                    label="CPF"
                                    disabled
                                    name="document"
                                    value={nutritionist.document?.value}
                                    onChange={(e) => setNutritionist({ ...nutritionist, document: { ...nutritionist.document, value: e.target.value } })}
                                    errorMessage={formErrors.cpf}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <AppMaskedTextField
                                    id="phoneNumber"
                                    fullWidth
                                    mask="(99) 99999-9999"
                                    label="Telefone"
                                    name="phoneNumber"
                                    value={nutritionist.phoneNumber}
                                    onChange={(e) => formService.setInputValue("phoneNumber", e.target.value)}
                                    errorMessage={formErrors.phoneNumber}
                                />
                            </Grid>
                            <Grid item md={3} xs={12} >
                                <AppTextField
                                    id="crn"
                                    fullWidth
                                    label="CRN"
                                    name="crn"
                                    value={nutritionist.crn}
                                    onChange={(e) => formService.setInputValue("crn", e.target.value)}
                                    errorMessage={formErrors.crn}
                                />
                            </Grid>
                        </Grid>
                        <AppErrorList errors={errors} />
                    </Box>
                    <Box sx={{ display: "flex", mt: 4 }}>
                        <Typography component="h3" variant="h5" sx={{ textAlign: "center", width: "100%", fontWeight: "bold", mt: 2 }}>Assim ficará os cabeçalhos de seus documentos:</Typography>
                    </Box>
                    <Divider sx={{ mt: 1, mb: 4 }} />
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", mt: 2 }}>
                        <Box sx={ isMobile ? 
                                    { display: "flex", maxWidth: "200px", flexWrap: "wrap", gap: 2 } : 
                                    { display: "flex", flexWrap: "nowrap", maxWidth: "600px", maxHeight: "80px" }}>

                            <img style={ isMobile ? 
                                    { display: "flex", maxWidth: "270px", maxHeight: "63px", flexWrap: "wrap", gap: 2 } : 
                                    { width: "270p", maxHeight: "63px" }} src={ urlLogo !== null ? `${baseUrlImage}${urlLogo}` : logo} alt="logo" />

                            <Box sx={{ ml: 2, display: "flex", flexDirection: "column", justifyContent: "center" }} >
                                <div dangerouslySetInnerHTML={{ __html: nutritionist.signatureText || "" }}></div>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", mt: 2, gap: 2, justifyContent: "center" }}>

                        <Fab size="small" aria-label="edit" onClick={handleLogoButtonClick}
                            sx={{
                                zIndex: 1,
                                color: "text.secondary",
                                boxShadow: "none"
                            }} >
                            <RefreshIcon />
                        </Fab>
                        <input accept=".jpg,.jpeg,.png" ref={logoInputRef} type="file" onChange={handleLogoChange} style={{ display: "none" }} />

                        <Fab size="small" aria-label="edit" onClick={handleRemoveLogo}
                            sx={{
                                zIndex: 1,
                                color: "text.secondary",
                                boxShadow: "none"
                            }} >
                            <Delete />
                        </Fab>
                    </Box>
                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        <AppRichText hasImage={true} text={nutritionist.signatureText || ""} setText={handleSignatureText} />
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", mt: 2 }}>
                        <AppButton
                            variant='contained'
                            type="submit"
                            startIcon={<Save />}
                            label="Salvar"
                            fullWidth={false}
                        />
                    </Box>
                </form>
            </AppCard>
        </ AppMainLayout>
    );

};

export default MyProfilePage;

