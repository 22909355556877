import {Box, Chip, Divider, FormLabel, TextField, Typography} from "@mui/material";
import React, { Dispatch, FC, ReactNode, SetStateAction, useCallback, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AppButton from "./AppButton";
import { useDialog } from "../../hooks/core/AppDialog/AppDialogProvider";

interface AppChipSelectModalProps {
  label?: string;
  optionsLabel?: string;
  chips: string[];
  selectedChips: string[];
  setSelectedChips: Dispatch<SetStateAction<string[]>>;
  placeholder?: string;
}


const AppChipSelectModal: FC<AppChipSelectModalProps> = ({ label, optionsLabel, chips = [], selectedChips = [], setSelectedChips }) => {

  const dialog = useDialog();
  const isActiveFromLocalStorage = localStorage.getItem("AppChipSelectModal-IsActive");

  const [isActive, setIsActive] = useState(isActiveFromLocalStorage !== null ? JSON.parse(isActiveFromLocalStorage) : false);
  const [isAddingNew, setIsAddingNew] = useState(false);

  const handleSelect = useCallback((chip: string) => {
    setSelectedChips([...selectedChips, chip]);
  }, [setSelectedChips]);

  const handleDeselect = useCallback((chip: string) => {
    const newList = selectedChips.filter(s => s !== chip);
    setSelectedChips(newList);
  }, [setSelectedChips]);

  const handleAddNew = useCallback((event: React.KeyboardEvent) => {
    const target = event.target as HTMLInputElement;
    if (event.key === "Enter") {
      const newChip = target.value;

      if (newChip === "") {
        return;
      }

      if (selectedChips.includes(newChip)) {
        dialog.alert({ title: "Opção já existe", message: `A opção "<b>${newChip}</b>" já existe na lista de opções e não pode ser adicionada novamente`, severity: "warning" });
        target.value = "";
        return;
      }

      setSelectedChips([...selectedChips, newChip]);
      setIsAddingNew(false);

    }

  }, [dialog, selectedChips, setSelectedChips]);

  const ChipContainer = useCallback(({ children, placeholder, bordered, title, onClick }: {
    children: ReactNode,
    placeholder?:string | null,
    title?: string,
    bordered?: boolean,
    onClick?: () => void }) => {
    let borderStyle = bordered ? { border: '1px solid #ccc', borderRadius: "8px" } : {};
    return (
      <Box title={title} sx={{ cursor: "pointer", width: '100%', minHeight: "52px", display: 'flex', justifyContent: 'flex-start', alignItems: "center", flexWrap: 'wrap', gap: 1, p: 1, ...borderStyle }} onClick={onClick} >
        {children}
        {placeholder &&
            <Typography sx={{
                fontStyle:"italic", fontSize:".7rem", pl:1, color:"text.secondary", opacity:".7"}}
            >
              {placeholder}
            </Typography>}
      </Box>
    );
  }, []);

  const availableChips = chips.filter(chip => !selectedChips.includes(chip));


  return (
    <Box>
      {label && <FormLabel sx={{ mb: 1, fontWeight: "bold", color:"text.primary" }}>{label}</FormLabel>}
      <ChipContainer
        title={isActive ? "Clique para ocultar as opções de controle" : "Clique para exibir as opções de controle"}
        bordered
        placeholder={selectedChips.length === 0 && !isActive ? "Clique aqui para selecionar doenças ou condições crônicas relacionadas ao paciente." : null}
        onClick={() => { setIsActive(!isActive); }}>
        {selectedChips.map((chip, index) => {
          return (
            <Chip key={"chip-" + index} clickable color="secondary" label={chip} onDelete={() => handleDeselect(chip)} />
          );
        })}
      </ChipContainer>
      {isActive && <Box sx={{ backgroundColor: "#fff", boxShadow: "0 0 5px #ccc", borderRadius: "8px", p: 2 }}>
        {optionsLabel && <FormLabel sx={{ mb: 1, ml: 2, fontWeight: "bold" }}>{optionsLabel}</FormLabel>}
        <ChipContainer>
          {availableChips.map((chip, index) => {
            return (
              <Chip key={"chip-" + index} clickable label={chip} onClick={() => handleSelect(chip)} />
            );
          })}

        </ChipContainer>

        {isAddingNew && <TextField sx={{ mb: 1, ml: 1 }} autoFocus variant="outlined" onKeyDown={handleAddNew} />}
        {!isAddingNew && <AppButton sx={{ mb: 1, ml: 1 }} color="secondary" size="small" startIcon={<AddIcon />} label="Adicionar" onClick={() => { setIsAddingNew(true) }} />}
        <Divider />
        <AppButton startIcon={<CancelIcon />} label="Fechar" onClick={() => { setIsActive(false); setIsAddingNew(false); }}
        />
      </Box>}
    </Box>
  );
}

export default AppChipSelectModal;