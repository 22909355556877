    import React, { FC, useState } from "react";
    import { AppDietPlanMeal, AppFoodDietPlan } from "../types";
    import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
    import AppTextField from "../../../../../components/forms/AppTextField";
    import { LabelType } from "../../../../../components/forms/enums/LabelType";
    import { BookmarkAdd, Delete, FileCopy } from "@mui/icons-material";
    import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
    import { AddItemArea } from "./AddItemArea";
    import FoodSelection from "./FoodSelection";

    export const MealBox: FC<AppDietPlanMeal> = ({ id, description, foods }) => {

      const [internalFoods, setInternalFoods] = useState<AppFoodDietPlan[]>(foods || []);
      const [viewSelectFoodComponent, setViewSelectFoodComponent] = useState<boolean>(false);


      const handleAddFood = (newFood: AppFoodDietPlan)=> {
        internalFoods.push(newFood);
        setInternalFoods(internalFoods);
      }

      return (
        <Box
          sx={{ display: "flex",
            borderRadius: 4,
            p: 4,
            mt: 2,
            paddingRight: 0,
            boxShadow: "0px 0px 4px #ccc",
            position: "relative"
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              top: 0,
              backgroundColor: "#fff",
              marginTop: "-10px",
              fontSize: "16px",
              fontWeight: "bold",
              pl: 1,
              pr: 1
            }}
          >
            {description || "Nova Refeição"}
          </Typography>

          <Box sx={{ display: "flex", width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <AppTextField
                  fullWidth
                  name={"description"}
                  value={description}
                  label={"Descrição"}
                  labelType={LabelType.INLINE}
                />
              </Grid>
              <Grid item xs={5} sm={5} md={4}>
                <AppTextField
                  fullWidth
                  type={"time"}
                  name={"time"}
                  label={"Horário Planejado"}
                  labelType={LabelType.INLINE}
                />
              </Grid>
              <Grid item xs={12}>
                  {internalFoods?.length > 0 &&
                      <Grid sx={{ mb:2}} container spacing={2}>
                          <Typography sx={{p:2}} variant={"h6"}>Alimentos</Typography>
                          {internalFoods!.map((food, index) => (
                              <Grid key={`${id}-food-${index}`} item xs={12} sm={12} md={12}>
                                  <Typography variant={"h6"}>{food.portion} de {food.name}</Typography>
                              </Grid>
                          ))}

                      </Grid>
                  }
                {internalFoods?.length === 0 && !viewSelectFoodComponent  ?
                  <AddItemArea
                    title={"Nenhum alimento adicionado à refeição"}
                    subtitle={"Adicione um alimento clicando abaixo"}
                    actionText={"Adicionar alimento"}
                    onClick={() => {
                       setViewSelectFoodComponent(true);
                    }}
                  />
                  :
                  <FoodSelection onArrowBackClicked={()=>setViewSelectFoodComponent(false)} onFoodSelected={handleAddFood} />
                }


              </Grid>
            </Grid>

            <Box  sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", p: 1}} >
              <Tooltip title={"Excluir essa refeição"} placement={"left"}>
                <IconButton sx={{}}>
                  <Delete sx={{ color: "#777" }} />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Desativar essa refeição"} placement={"left"}>
                <IconButton sx={{}}>
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Salvar refeição como modelo"} placement={"left"}>
                <IconButton sx={{}}>
                  <BookmarkAdd />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Clonar refeição"} placement={"left"}>
                <IconButton sx={{}}>
                  <FileCopy />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      );
    };