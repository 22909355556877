import React from "react";
import {Box, Grid, IconButton, Tooltip} from "@mui/material";
import AppTextField from "../../../../components/forms/AppTextField";
import {LabelType} from "../../../../components/forms/enums/LabelType";
import AppSelectable from "../../../../components/forms/AppSelectable";
import {AddItemArea} from "./components/AddItemArea";
import AddIcon from "@mui/icons-material/Add";
import {daysInWeek} from "./constants";
import {MealBox} from "./components/MealBox";
import {v4 as uuidv4} from "uuid";

const NewDietPlanManual = () => {

    const [meals, setMeals] = React.useState<any[]>([]);

    const handleAddMeal = () => {
        setMeals((m) => [
            ...m,
            {
                id: uuidv4(),
                description: "",
                foods: [],
            },
        ]);
    };

    return (
        <Grid container spacing={2} sx={{ mt: 0, mb: 4, p: 1 }}>
            <Grid item xs={12} sm={12} md={5}>
                <AppTextField
                    label="Descrição do plano"
                    name="descricaoPlano"
                    labelType={LabelType.INLINE}
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} sm={12} md={7} flex={1} alignItems={"flex-end"}>
                <Grid container justifyContent={{ md: "flex-end" }}>
                    <AppSelectable
                        selectableValues={daysInWeek}
                        label="Dias da semana"
                        onChanged={(selectedValues) => console.log(selectedValues)}
                        value={["01", "02", "03", "05", "06"]}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} flex={1} alignItems={"flex-end"}>
                {meals.length === 0 ? (
                    <AddItemArea
                        title={"Nenhuma refeição adicionada nesse plano"}
                        subtitle={
                            "Você deve adicionar todas as refeições que o paciente fará nos dias selecionados"
                        }
                        actionText={"Adicionar primeira refeição"}
                        onClick={handleAddMeal}
                    />
                ) : (
                    <Grid item xs={12}>
                        {meals.map((meal, index) => (
                            <MealBox
                                key={`meal-index-${index}`}
                                id={meal.id}
                                description={meal.description}
                                foods={meal.foods}
                                planedTime={meal.planedTime}
                            />
                        ))}

                        <Box sx={{ mt: 2, display: "flex", gap: 2, alignItems: "center", justifyContent: "center" }}>
                            <Tooltip title={"Adicionar nova refeição"}>
                                <IconButton size={"large"} sx={{ backgroundColor: "#f2f2f2" }} onClick={handleAddMeal} >
                                    <AddIcon fontSize={"large"} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default NewDietPlanManual;