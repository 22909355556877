import AppMainLayout from "../../../../components/layout/AppMainLayout";
import AppMainHeader from "../../../../components/layout/AppMainHeader";
import AppCard from "../../../../components/layout/AppCard";
import AppInterationsPageHeader, {AppInterationHeaderAction} from "../components/AppInterationsPageHeader";
import {Box, Divider, Grid, Typography} from "@mui/material";
import NewDietPlanManual from "./NewDietPlanManual";
import React from "react";
import {Bookmark, BookmarkAdd, ReportOff, Save, Share, Visibility} from "@mui/icons-material";

const headerActions: AppInterationHeaderAction[] = [
    {
        label: "Salvar",
        featured: true,
        icon: <Save/>,
        onClick: () => {
        },
    },
    {
        label: "Salvar como modelo",
        showAsIconButton: true,
        featured: true,
        icon: <BookmarkAdd/>,
        onClick: () => {
        },
    },
    {
        label: "Compartilhar",
        featured: true,
        showAsIconButton: true,
        separated: true,
        icon: <Share/>,
        onClick: () => {
        },
    },
    {
        label: "Visualizar",
        icon: <Visibility/>,
        onClick: () => {
        },
    },
    {
        label: "Carregar Modelo",
        icon: <Bookmark/>,
        onClick: () => {
        },
    },
    {
        label: "Inativar Plano",
        separated: true,
        icon: <ReportOff/>,
        onClick: () => {
        },
    },
];

const NewDietPlanImport = () => {
    return (
       <Box>Criar plano a partir de um modelo</Box>
    );
};

export default NewDietPlanImport;