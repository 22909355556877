import { FC, useCallback, useEffect, useMemo, useState } from "react";
import AppCard from "../../../../components/layout/AppCard";
import AppMainHeader from "../../../../components/layout/AppMainHeader";
import AppMainLayout from "../../../../components/layout/AppMainLayout";
import AppInterationsPageHeader from "../components/AppInterationsPageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { Save as SaveIcon } from '@mui/icons-material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Box, Grid } from "@mui/material";
import CircumferencesSection from "./CircumferencesSection";
import BodyCompositionSection from "./BodyCompositionSection";
import { useAnthropometricStore } from "../../Stores/Anthropometric/AnthropometricStore";
import { PatientType } from "./enums/PatientType";
import AppTextField from "../../../../components/forms/AppTextField";
import { usePatientStore } from "../../Stores/PatientStore/PatientStore";
import { AnthropometricChildCaculateType } from "./services/types";
import { Gender } from "../../../../domain/interfaces/Patient";
import { round } from "../../../../utils/numberUtils";
import AppMaskedNumberField from "../../../../components/forms/AppMaskedNumberField";
import { PatientService } from "../../services/PatientService";
import ResultsSection from "./ResultsSection";
import ChildAnthropometricService from "./services/ChildCalculate/ChildAnthropometricService";
import AppLoading from "../../../../components/utils/AppLoading";
import { anthropometricServiceFactory } from "./services/Factories/anthropometricsResultsFactory";
import { LabelType } from "../../../../components/forms/enums/LabelType";
import InfoPatient from "../../components/InfoPatient";
import { Anthropometric } from "./types/Anthropometric";
import { BodyCompositionItemResult } from "./types/BodyComposition/BodyCompositionItemResult";
import { BodyComposition } from "./types/BodyComposition/BodyComposition";
import { Circuferences } from "./types/Circuferences/Circuferences";
import api from "../../../../services/api";
import { useAppToast } from "../../../../hooks/core/AppToastContextProvider";
import * as Yup from 'yup';
import FormService, { FormErrors } from "../../../../services/form.service";
import AppErrorList from "../../../../components/forms/AppErrorList";
import { useDialog } from "../../../../hooks/core/AppDialog/AppDialogProvider";
import { getFormattedBackendDate, getFormattedDate, getFormattedDateTime } from "../../../../utils/dateUtils";
import { AxiosResponse } from "axios";
import { getDefaultAnthropometric } from "./initialDataForm/bodyCompositionInitialData";

const formSchema = Yup.object({
    weight: Yup
        .number()
        .min(1, "Obrigatório que peso seja maior que 1Kg.")
        .max(300, "Obrigatório que peso seja menor que 300Kg.")
        .required('Obrigatório informar o peso do paciente.'),
    height: Yup
        .number()
        .min(0.20, "Obrigatório que a altura seja maior que 0,20m.")
        .max(3, "Obrigatório que a altura seja menor ou igual a 3m.")
        .required('Obrigatório informar a altura do paciente.')
});

const toastSuccessValues = {
    update: {
        title: "Antropometria atualizada!",
        description: "Antropometria atualizada com sucesso!"
    },
    create: {
        title: "Antropometria criada!",
        description: "Antropometria criada com sucesso!"
    }
}

const AnthropometricPage: FC = () => {
    const { patient } = usePatientStore();
    const params = useParams();
    const { anthropometricId } = params;
    const [mode] = useState<"edit" | "create">(anthropometricId !== undefined ? "edit" : "create");
    const [isCreate] = useState<boolean>(anthropometricId !== undefined ? false : true);
    const [textBtnSave, setTextBtnSave] = useState<string>("Salvar");
    const [disabledBtnSave, setDisabledBtnSave] = useState<boolean>(false);
    const { addToast } = useAppToast();
    const navigate = useNavigate();
    const dialog = useDialog();

    const store = useAnthropometricStore(state => state.data);
    const setAnthropometricDataStore = useAnthropometricStore(state => state.setAnthropometricData);
    const setData = useAnthropometricStore(state => state.setData);
    const data = store;

    const [isAllMeasures, setIsAllMeasures] = useState(false);
    const [isBmi, setIsBmi] = useState(false);
    const [tableResult, setTableResult] = useState([] as BodyCompositionItemResult[]);
    const [showTableResult, setShowTableResult] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({} as FormErrors);
    const [erros, setErros] = useState([] as string[]);

    const [isChildLoading, setIsChildLoading] = useState(false);
    const [isChildValueUpdated, setIsChildValueUpdated] = useState(false);

    const formService = useMemo(() => new FormService(data, setAnthropometricDataStore, setErros, setFormErrors), [data, setAnthropometricDataStore])

    const getIdealWeight = useCallback((height: number): number => {
        if (height === 0) return 0;
        // Male recomendation BMI
        let recomendationBmi = 22;
        if (patient.gender === Gender.Female) {
            // Female recomendation BMI
            recomendationBmi = 20.8;
        }
        return round(recomendationBmi * Math.pow(height, 2));
    }, [patient.gender]);

    const updateBodyCompositionStore = useCallback(async (anthropometric: Anthropometric, bodyComposition: BodyComposition) => {
        const { results } = bodyComposition.bodyCompositionResult;

        anthropometric = {
            ...anthropometric,
            idealWeight: getIdealWeight(Number(anthropometric.height))
        }

        setTableResult(results);
        setShowTableResult(results.length > 0);

        const newData = {
            ...anthropometric,
            bodyComposition: bodyComposition
        }
        setAnthropometricDataStore(newData);
    }, [setTableResult, setShowTableResult, setAnthropometricDataStore, getIdealWeight]);

    const updateAnthropometricsAllMeasures = useCallback((anthropometric: Anthropometric) => {
        const anthropometricService = anthropometricServiceFactory(anthropometric, patient);
        setIsBmi(anthropometricService.isWeigthAndHeight());
        setIsAllMeasures(anthropometricService.isAllMeasures());
    }, [setIsBmi, setIsAllMeasures, patient]);

    const updateAnthropometricsResult = useCallback((anthropometric: Anthropometric) => {
        updateAnthropometricsAllMeasures(anthropometric);
        const anthropometricService = anthropometricServiceFactory(anthropometric, patient);
        let bodyComposition = anthropometricService.calculate();

        updateBodyCompositionStore(anthropometric, bodyComposition);

    }, [updateBodyCompositionStore, patient, updateAnthropometricsAllMeasures]);

    const updateChildAnthropometricResult = useCallback(async () => {
        try {
            if (!isChildValueUpdated) return;

            const anthropometricCalculate = {
                height: data.height,
                weight: data.weight,
                patientId: patient.id,
                birthDate: getFormattedBackendDate(patient.birthDate),
                gender: patient.gender,
                data: data.bodyComposition
            } as AnthropometricChildCaculateType;

            setShowTableResult(false);
            setIsChildLoading(true);
            setTextBtnSave("Processando...");
            setDisabledBtnSave(true);

            let service = new ChildAnthropometricService(anthropometricCalculate);
            setIsAllMeasures(service.isAllMeasures());
            setIsBmi(service.isAllMeasures());
            var result = await service.calculate();

            updateBodyCompositionStore(data, result.bodyComposition);

        } catch (errors) {
            formService.handleErros(errors);
        } finally {
            setIsChildLoading(false);
            setTextBtnSave("Salvar");
            setDisabledBtnSave(false);
        }
    }, [setIsAllMeasures, setIsBmi, patient.id, data, updateBodyCompositionStore, setShowTableResult, isChildValueUpdated, formService, patient.birthDate, patient.gender]);

    const handleOnChangeAnthropometric = useCallback((fieldName: keyof Anthropometric, value: any) => {
        setErros([]);
        if (data.patientType === PatientType.Child) {
            const valuePersisted = data[fieldName];

            if (valuePersisted === value) {
                setIsChildValueUpdated(false);
                return;
            };

            setIsChildValueUpdated(true);
            setData(fieldName, value);
            return;
        }
        const newData = { ...data, [fieldName]: value };
        updateAnthropometricsResult(newData);
    }, [updateAnthropometricsResult, data, setData, setErros, setIsChildValueUpdated]);

    const handleOnBlurAnthropometric = useCallback(async () => {
        if (data.patientType === PatientType.Child)
            await updateChildAnthropometricResult()
    }, [updateChildAnthropometricResult, data.patientType]);

    const handleOnChangeBodyComposition = useCallback((bodyComposition: BodyComposition) => {
        const anthropometricData = { ...data, bodyComposition: bodyComposition };
        updateAnthropometricsResult(anthropometricData);
    }, [updateAnthropometricsResult, data]);

    const handleOnChangeCircuferences = useCallback((circuferences: Circuferences) => {
        const newData = { ...data, circuferences: circuferences };
        setAnthropometricDataStore(newData);
    }, [data, setAnthropometricDataStore]);

    const handleSaveAnthropometric = useCallback(async () => {
        let confirm: boolean = true;
        let message = ""

        try {
            setFormErrors({});

            await formSchema.validate(data, { abortEarly: false });

            if (!isBmi || !isAllMeasures) message = "Não foram preenchidos todos os campos para para calcúlo dos resultados.";
            confirm = await dialog.confirm({ title: "Confirma envio dos dados?", message, invert: false });
            if (!confirm) return;

            setTextBtnSave("Processando...");
            setDisabledBtnSave(true);
            let response: AxiosResponse;
            let toastValues;

            if (mode === "edit") {
                toastValues = toastSuccessValues.update;
                response = await api.put(`/${patient.id}/anthropometrics/${data.id}`, data);
            } else {
                toastValues = toastSuccessValues.create;
                response = await api.post(`/${patient.id}/anthropometrics`, data);
            }

            if (response.status === 201 || response.status === 200) {
                addToast({
                    title: toastValues.title,
                    description: toastValues.description,
                    type: "success"
                });

                navigate(`/meus-pacientes/${patient.id}/visualizar`);

                return;
            }

            addToast({
                title: "Houve uma falha na criação da Antropometria.",
                description: "Caso o erro persista entre em contato com o suporte.",
                type: "error"
            });

        } catch (errors) {
            formService.handleErros(errors);
        } finally {
            setTextBtnSave("Salvar");
            setDisabledBtnSave(false);
        }
    }, [data, patient.id, addToast, navigate, dialog, formService, isAllMeasures, isBmi, mode]);

    const Sections = useMemo(() => {
        return (
            <>
                <Grid item xs={12}>
                    <CircumferencesSection complete={true} updateResults={handleOnChangeCircuferences} />
                </Grid>
                {data.patientType !== PatientType.Child &&
                    <Grid item xs={12}>
                        <BodyCompositionSection updateResults={handleOnChangeBodyComposition} complete={true} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <ResultsSection isChildLoading={isChildLoading} showTableResult={showTableResult} isAllMeasures={isAllMeasures} isBmi={isBmi} tableResult={tableResult} />
                </Grid>
            </>
        );
    }, [handleOnChangeBodyComposition, handleOnChangeCircuferences, isAllMeasures, isBmi, showTableResult, tableResult, data.patientType, isChildLoading]);


    const getAnthropometricData = useCallback(async () => {
        setIsLoading(true);
        let response = await api.get(`/${patient.id}/anthropometrics/${anthropometricId}`);
        const data = response.data as Anthropometric;

        setAnthropometricDataStore(data);
        updateBodyCompositionStore(data, data.bodyComposition);

        if (data.patientType === PatientType.Child) {
            const { weight, height } = data;
            const isAllMeasures = ChildAnthropometricService.isAllMeasures(weight, height);
            setIsAllMeasures(isAllMeasures);
            setIsBmi(isAllMeasures);
        }

        if (data.patientType !== PatientType.Child)
            updateAnthropometricsAllMeasures(data);

        setIsLoading(false);
    }, [setIsLoading, anthropometricId, setAnthropometricDataStore, patient.id, updateAnthropometricsAllMeasures, updateBodyCompositionStore]);

    const handleRemove = useCallback(async () => {
        let confirm = await dialog.confirm({ title: "Confirma a exclusão desta antropometria?", message: "", invert: false });
        if (!confirm) return;
    
        setIsLoading(true);
    
        const response = await api.delete(`/${patient.id}/anthropometrics/${data.id}`);
    
        setIsLoading(false);
    
        if (response.status === 200) {
          addToast({
            title: "Antropometria excluida!",
            description: "Antropometria excluida com sucesso!",
            type: "success"
          });
    
          navigate(`/meus-pacientes/${patient.id}/visualizar`);
          return;
        }
    
        addToast({
          title: "Falha na exclusão da antropometria!",
          description: "Houve uma falha na exclusão da antropometria!",
          type: "error"
        });
    
      }, [addToast, dialog, navigate, patient.id, data.id]);

    useEffect(() => {
        if (anthropometricId !== undefined) {
            getAnthropometricData();
            return;
        }
        const ageInYears = PatientService.getPatientAge(patient);
        let ageInDays = null;
        let ageInMonths = null;
        let patientType = PatientService.getPatientType(patient);

        if (patientType === PatientType.Child) {
            ageInDays = PatientService.getAgeInDays(patient);
            ageInMonths = PatientService.getAgeInMonths(patient);
        };

        const defaultAnthropometric = getDefaultAnthropometric();

        const anthropometric = {
            ...defaultAnthropometric,
            ageInYears: ageInYears,
            ageInDays: ageInDays,
            ageInMonths: ageInMonths,
            patientType: patientType
        } as Anthropometric;

        setAnthropometricDataStore(anthropometric);

    }, [setAnthropometricDataStore, getAnthropometricData, anthropometricId, patient]);

    return (
        <AppMainLayout>
            <AppLoading isLoading={isLoading} />
            <AppMainHeader hasBreadCrumbs={true} />
            <AppCard cutPaddingTop >
                <AppInterationsPageHeader
                    title={mode === "create" ? "Nova Antropometria" : "Edição Antropometria"}
                    subtitle={patient.name}
                    actions={
                        [
                        {
                            label: textBtnSave,
                            icon: <SaveIcon />,
                            onClick: handleSaveAnthropometric,
                            featured: true,
                            disabled: disabledBtnSave
                        },
                        {
                            label: "Excluir",
                            icon: <DeleteIcon />,
                            onClick: handleRemove,
                            featured: false,
                            hide: isCreate
                        },
                    ]
                    }
                />

                <Box sx={{ mt: 4, mb: 4, display: "flex", alignItems: "center", flexWrap: "wrap", gap: 4 }}>
                    <InfoPatient title="Data de Criação:" data={getFormattedDateTime(data.createdDate)} />
                    <InfoPatient title="Data de Alteração:" data={getFormattedDateTime(data.updatedDate)} />
                    <InfoPatient title="Tipo de Paciente:" data={PatientService.getPatientTypeDescription(data.patientType)} />
                    <InfoPatient title="Sexo:" data={PatientService.getPatientGenderDescription(patient.gender)} />
                    <InfoPatient title="Data de Nascimento:" data={getFormattedDate(patient.birthDate)} />
                    <InfoPatient title="Idade de Referência:" data={`${data.ageInYears} ano(s)`} />

                    {data.patientType === PatientType.Child &&
                        <>
                            <InfoPatient title="Mês de Referência:" data={`${data.ageInMonths} mês(es)`} />
                            <InfoPatient title="Dias de Referência:" data={`${data.ageInDays} dia(s)`} />
                        </>
                    }
                </Box>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        <AppTextField
                            labelType={LabelType.INLINE}
                            fullWidth
                            multiline
                            rows={4}
                            name="description_anthropometric"
                            type="text"
                            label="Descrição"
                            value={data.description}
                            onChange={(e) => handleOnChangeAnthropometric("description", e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                    <Grid item md={4} xs={12}>
                        <AppMaskedNumberField
                            labelType={LabelType.INLINE}
                            fullWidth
                            name="height"
                            label="Altura*"
                            value={data.height}
                            onValueChange={(value) => handleOnChangeAnthropometric("height", value)}
                            onBlur={() => handleOnBlurAnthropometric()}
                            placeholder="0,00 m"
                            suffix="mt"
                            maxValue={3}
                            errorMessage={formErrors.height}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <AppMaskedNumberField
                            labelType={LabelType.INLINE}
                            fullWidth
                            name="weight"
                            label="Peso*"
                            value={data.weight}
                            onValueChange={(value) => handleOnChangeAnthropometric("weight", Number(value))}
                            onBlur={handleOnBlurAnthropometric}
                            placeholder="0,00 Kg"
                            suffix="Kg"
                            maxValue={900}
                            errorMessage={formErrors.weight}
                        />
                    </Grid>
                    {data.patientType !== PatientType.Child &&
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="idealWeight"
                                label="Peso Ideal"
                                value={data.idealWeight}
                                onValueChange={(value) => handleOnChangeAnthropometric("idealWeight", Number(value))}
                                placeholder="0,00 Kg"
                                suffix="Kg"
                                readOnly
                                tooltip={"Peso ideal conforme IMC."}
                            />
                        </Grid>
                    }
                </Grid>

                <AppErrorList errors={erros} />

                <Grid container spacing={0} sx={{ mt: 2 }}>
                    {Sections}
                </Grid>
            </AppCard>
        </AppMainLayout >
    )
}

export default AnthropometricPage;