import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box, Grid, Step, StepLabel, Stepper } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import AppButton from '../../../components/forms/AppButton';
import { Gender, Patient } from '../../../domain/interfaces/Patient';
import FormService, { FormErrors } from '../../../services/form.service';
import PatientBaseFields from './PatientBaseFields';
import AppErrorList from '../../../components/forms/AppErrorList';
import PatientAddressFields from './PatientAddressFields';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/core/AuthProvider';
import { useAppToast } from '../../../hooks/core/AppToastContextProvider';
import AppLoading from '../../../components/utils/AppLoading';

const formSchema = Yup.object({
  name: Yup
    .string()
    .required('Obrigatório informar o nome.'),
  birthDate: Yup
    .date()
    .required('Obrigatório informar a data de nascimento.')
    .max(moment().subtract(1, 'days').format('YYYY-MM-DD'), 'Data de nascimento inválida.'),
  email: Yup
    .string()
    .email("O e-mail deve ser válido.")
    .required('Obrigatório informar o email.'),
  gender: Yup
    .string()
    .required('Obrigatório informar o sexo.'),
});

const initialData: Patient = {
  name: "",
  birthDate: moment().format("YYYY-MM-DD"),
  email: "",
  isPregnant: false,
  profession: "",
  observation: "",
  gender: Gender.Male,
  address: {
    street: "",
    number: "",
    complement: "",
    city: "",
    state: "",
    postalCode: "",
    country: "Brasil"
  },
  isActive: true
}


const NewPatientForm: React.FC = () => {
  const [formErros, setFormErros] = useState({} as FormErrors);
  const [erros, setErros] = useState([] as string[]);
  const [newPatient, setNewPatient] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { addToast } = useAppToast();

  const passos = [
    'Dados do Paciente',
    'Dados de Aplicativo (em breve)',
  ];

  const formService = new FormService(newPatient, setNewPatient, setErros, setFormErros);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const nutritionistId = user?.id;
      await formSchema.validate(newPatient, { abortEarly: false });

      const response = await api.post(`/nutritionists/${nutritionistId}/patients`, newPatient);

      if(response.status === 201){
        const patientId = response.data;

        addToast({
          title: "Paciente Cadastrado",
          description: "Paciente adicionado com sucesso!",
          type: "success"
        });

        navigate(`/meus-pacientes/${patientId}/visualizar`);
      }

    } catch (errors) {
      formService.handleErros(errors);
    }
    setIsLoading(false);
  }

  const onClean = () => {
    setNewPatient(initialData);
  }

  return (
    <Grid container columnGap={2}>
      <AppLoading isLoading={isLoading} />

      <PatientBaseFields
        patient={newPatient}
        setPatient={setNewPatient}
        formService={formService}
        formErrors={formErros}
      />

      <PatientAddressFields
        patient={newPatient}
        setPatient={setNewPatient}
        formService={formService}
        formErrors={formErros}
      />

      <AppErrorList errors={erros} />
      
      <Box sx={{ width: '70%', margin:"0 auto", mt: 4 }}>
        <Stepper activeStep={0} alternativeLabel>
          {passos.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="end"
        justifyContent="space-between"
        md={12} 
        mt={2}
        gap={2}>

        <AppButton
          variant='outlined'
          type="button"
          label="Limpar Campos"
          onClick={onClean}
          fullWidth={false}
        />
        

        <AppButton
          variant='contained'
          type="button"
          startIcon={<Check />}
          label="Salvar Paciente"
          onClick={handleSubmit}
          fullWidth={false}
        />

      </Grid>


    </Grid>
  );
}

export default NewPatientForm;