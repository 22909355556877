import { useCallback, useEffect, useMemo, useState } from 'react';
import api from "../../../../services/api";
import { Save as SaveIcon } from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AppMainLayout from '../../../../components/layout/AppMainLayout';
import AppCard from '../../../../components/layout/AppCard';
import AppMainHeader from '../../../../components/layout/AppMainHeader';
import AppInterationsPageHeader from '../components/AppInterationsPageHeader';
import { ROUTES } from '../../../../constants/routes';
import { Chip, Grid } from '@mui/material';
import AppTextField from '../../../../components/forms/AppTextField';
import LifeHabitSection from './LifeHabitSection';
import PathologiesSection from './PathologiesSection';
import ClinicEvaluationSection from './ClinicEvaluationSection';
import MetabolicScreeningSection from './MetabolicScreeningSection';
import { useAnamnesisStore } from '../../Stores/AnamnesisStore/AnamnesisStore';
import { usePatientStore } from '../../Stores/PatientStore/PatientStore';
import { useAnamnesisRefDataStore } from '../../Stores/AnamnesisStore/AnamnesisRefDataStore';
import { AnamnesisData, ApiMetabolicScreeningItem } from '../../Stores/AnamnesisStore/types.d';
import moment from 'moment';
import { useAppToast } from "../../../../hooks/core/AppToastContextProvider";
import { AxiosResponse } from "axios";
import { useDialog } from '../../../../hooks/core/AppDialog/AppDialogProvider';
import { Delete as DeleteIcon, Share } from '@mui/icons-material';
import AppLoading from '../../../../components/utils/AppLoading';
import { pdf } from '@react-pdf/renderer';
import BasePdf from '../../components/BasePdf';

const AnamnesisPage = () => {

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { patientId, anamnesisId } = params;
    const [mode] = useState<"edit" | "create">(anamnesisId !== undefined ? "edit" : "create");
    const [isCreate] = useState<boolean>(anamnesisId !== undefined ? false : true);
    const [textBtnSave, setTextBtnSave] = useState<string>("Salvar");
    const { addToast } = useAppToast();
    const { patient } = usePatientStore();

    const setData = useAnamnesisStore(store => store.setData);
    const fillAllData = useAnamnesisStore(store => store.fillAllData);
    const resetData = useAnamnesisStore(store => store.resetData);
    const data = useAnamnesisStore(store => store.data);
    const setRefData = useAnamnesisRefDataStore(store => store.setData);
    const refData = useAnamnesisRefDataStore(store => store.data);
    const dialog = useDialog();
    const [isLoading, setIsLoading] = useState(false);


    let anamnesisData = location.state as AnamnesisData;

    const handleRemove = useCallback(async () => {
        let confirm = await dialog.confirm({ title: "Confirma a exclusão desta anamnese?", message: "", invert: false });
        if (!confirm) return;

        setIsLoading(true);

        const response = await api.delete(`/anamnesis/${anamnesisId}?patientId=${patientId}`);

        setIsLoading(false);

        if (response.status === 200) {
            addToast({
                title: "Anamnese excluida!",
                description: "Anamnese excluida com sucesso!",
                type: "success"
            });

            navigate(`/meus-pacientes/${patientId}/visualizar`);
            return;
        }

        addToast({
            title: "Falha na exclusão da anamnese!",
            description: "Houve uma falha na exclusão da anamnese!",
            type: "error"
        });

    }, [addToast, dialog, navigate, anamnesisId, patientId]);


    useEffect(() => {
        const fetchData = async () => {

            if (refData.metabolicScreeningItemsRef.length === 0) {
                const response = await api.get("refdata/anamnesis-metabolic-screenings");
                const itemsRef: ApiMetabolicScreeningItem[] = response.data;
                setRefData("metabolicScreeningItemsRef", itemsRef);
            }


            resetData();
            if (anamnesisData !== null) {
                anamnesisData.creationDate = new Date(anamnesisData.creationDate);
                anamnesisData.clinicEvaluation.lastMenstruationDate = moment(anamnesisData?.clinicEvaluation?.lastMenstruationDate);

                fillAllData(anamnesisData);
            }

            if (anamnesisId && !anamnesisData) {
                const response = await api.get(`anamnesis/${anamnesisId}`);
                const data = response.data as AnamnesisData;
                data.creationDate = new Date(data.creationDate);
                data.clinicEvaluation.lastMenstruationDate = moment(data?.clinicEvaluation?.lastMenstruationDate);

                fillAllData(data);
            }
        }
        fetchData();

    }, [fillAllData, anamnesisData, resetData, anamnesisId, refData.metabolicScreeningItemsRef.length, setRefData]);


    const Sections = useMemo(() => {
        return (
            <>
                <Grid container spacing={0} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <LifeHabitSection complete={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <PathologiesSection complete={false} />
                    </Grid>
                    <Grid item xs={12}>
                        <ClinicEvaluationSection complete={false} />
                    </Grid>
                    <Grid item xs={12}>
                        <MetabolicScreeningSection complete={false} />
                    </Grid>
                </Grid>
            </>
        );
    }, []);


    if (patientId !== patient.id) {
        navigate(ROUTES.MY_PATIENTS);
        return (<></>);
    }



    return (
        <AppMainLayout>
            <AppLoading isLoading={isLoading} />
            <AppMainHeader hasBreadCrumbs={true} />
            <AppCard cutPaddingTop>
                <AppInterationsPageHeader
                    title={mode === "create" ? "Nova Anamnese" : "Edição da Anamnese"}
                    subtitle={patient.name}
                    actions={
                        [
                            {
                                label: textBtnSave,
                                icon: <SaveIcon />,
                                featured: true,
                                onClick: async () => {
                                    setTextBtnSave("Processando...")

                                    try {
                                        setData("patientId", patientId);

                                        data.patientId = patientId!;
                                        let response: AxiosResponse<any, any>;

                                        if (data.id === null) {
                                            response = await api.post(`anamnesis`, data);
                                        } else {
                                            response = await api.put(`anamnesis/${data.id}`, data);
                                        }

                                        // On Create
                                        if (response.status === 201) {
                                            addToast({
                                                title: "Sucesso",
                                                description: "Anamnese criada com sucesso!",
                                                type: "success"
                                            });

                                            setTimeout(() => {
                                                navigate(`/meus-pacientes/${patientId}/anamnese/${response.data}`);
                                                window.location.reload();
                                            }, 1000);

                                        }
                                        // On Update
                                        if (response.status === 200) {

                                            addToast({
                                                title: "Sucesso",
                                                description: "Anamnese atualizada com sucesso!",
                                                type: "success"
                                            });

                                        }
                                    } catch (e) {
                                        addToast({
                                            title: "Erro",
                                            description: "Não foi possível salvar a anamnese. Tente novamente mais tarde ou contate o nosso suporte",
                                            type: "error"
                                        })
                                    }

                                    setTextBtnSave("Salvar")

                                }
                            },
                            {
                                label: "Compartilhar",
                                icon: <Share />,
                                featured: false,
                                onClick: async () => {
                                    const blob = await pdf(<BasePdf />).toBlob(); 
                                    const url = URL.createObjectURL(blob); 
                                    const a = document.createElement('a'); 
                                    a.href = url; 
                                    a.download = 'documento.pdf';
                                    a.click(); 
                                    URL.revokeObjectURL(url);
                                 }
                            },
                            {
                                label: "Excluir",
                                icon: <DeleteIcon />,
                                onClick: handleRemove,
                                featured: false,
                                hide: isCreate                               
                            }
                        ]
                    }
                />


                <Grid container spacing={2} sx={{ mb: 4, mt: 1 }}>
                    <Grid item xs={12}>
                        {
                            !isCreate &&
                            <Chip
                                label={"Data de criação: " + data.creationDate.toLocaleDateString()}
                            />
                        }
                    </Grid>

                </Grid>


                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        <AppTextField
                            fullWidth
                            multiline
                            rows={4}
                            name="notes"
                            type="text"
                            label="Caso Clínico (histórico, objetivo do paciente etc.)"
                            value={data.notes}
                            onChange={(e) => setData("notes", e.target.value)}
                        />
                    </Grid>
                </Grid>

                {Sections}

            </AppCard>
        </AppMainLayout>
    );
};

export default AnamnesisPage;