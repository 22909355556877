import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Box, FormControlLabel, Grid, Step, StepLabel, Stepper, Switch } from '@mui/material';
import { Check } from '@mui/icons-material';
import moment from 'moment';

import api from '../../../services/api';
import { Patient } from '../../../domain/interfaces/Patient';
import FormService, { FormErrors } from '../../../services/form.service';
import PatientBaseFields from './PatientBaseFields';
import PatientAddressFields from './PatientAddressFields';
import { useAuth } from '../../../hooks/core/AuthProvider';
import { useAppToast } from '../../../hooks/core/AppToastContextProvider';
import AppLoading from '../../../components/utils/AppLoading';
import AppButton from '../../../components/forms/AppButton';
import AppErrorList from '../../../components/forms/AppErrorList';
import PatientService from '../../../services/patient.service';

const formSchema = Yup.object({
  name: Yup
    .string()
    .required('Obrigatório informar o nome.'),
  birthDate: Yup
    .date()
    .required('Obrigatório informar a data de nascimento.')
    .max(moment().subtract(1, 'days').format('YYYY-MM-DD'), 'Data de nascimento inválida.'),
  email: Yup
    .string()
    .email("O e-mail deve ser válido.")
    .required('Obrigatório informar o email.'),
  gender: Yup
    .string()
    .required('Obrigatório informar o sexo.'),
});


interface Props {
  handleUpdatedPatient: () => void;
  initialData: Patient;
}

const PatientForm: React.FC<Props> = (props) => {

  const { handleUpdatedPatient, initialData } = props;

  const [formErros, setFormErros] = useState({} as FormErrors);
  const [erros, setErros] = useState([] as string[]);
  const [patient, setPatient] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const { addToast } = useAppToast();

  const passos = [
    'Dados do Paciente',
    'Dados de Aplicativo (em breve)',
  ];

  const formService = new FormService(patient, setPatient, setErros, setFormErros);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const nutritionistId = user?.id;
      await formSchema.validate(patient, { abortEarly: false });

      const response = await PatientService.patientUpdate(patient, nutritionistId);

      if (response.status === 200) {
        addToast({
          title: "Paciente Atualizado",
          description: "Paciente atualizado com sucesso!",
          type: "success"
        });
        handleUpdatedPatient();
      }

    } catch (errors) {
      formService.handleErros(errors);
    }
    setIsLoading(false);
  }

  const onClean = () => {
    setPatient(initialData);
  }

  useEffect(() => {
    console.log('patient', patient);
  }, []);

  return (
    <Grid container columnGap={2}>
      <AppLoading isLoading={isLoading} />

      <FormControlLabel control={
        <Switch
          defaultChecked={patient.isActive}
          value={patient.isActive}
          onChange={(e) => {
            setPatient({ ...patient, isActive: e.target.checked });
          }}
        />}
        sx={{ mb: 2 }}
        label="Ativo" />

      <PatientBaseFields
        patient={patient}
        setPatient={setPatient}
        formService={formService}
        formErrors={formErros}
        isEmailFieldDisabled={true}
      />

      <PatientAddressFields
        patient={patient}
        setPatient={setPatient}
        formService={formService}
        formErrors={formErros}
      />

      <AppErrorList errors={erros} />

      <Box sx={{ width: '70%', margin: "0 auto", mt: 4 }}>
        <Stepper activeStep={0} alternativeLabel>
          {passos.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="end"
        justifyContent="space-between"
        mt={2}
        md={12}
        gap={2} >

        <AppButton
          variant='outlined'
          type="button"
          label="Limpar Campos"
          onClick={onClean}
          fullWidth={false}
        />


        <AppButton
          variant='contained'
          type="button"
          startIcon={<Check />}
          label="Salvar Paciente"
          onClick={handleSubmit}
          fullWidth={false}
        />

      </Grid>


    </Grid>
  );
}

export default PatientForm;