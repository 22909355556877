import { Routes as ReactDomRoutes, Route } from "react-router-dom";
import Private from "./Route";
import { ROUTES } from "../constants/routes";
import PatientDetailPage from "../pages/MyPatients/DetailPage/PatientDetailPage";
import AnamnesisPage from "../pages/MyPatients/Interations/Anamnesis/AnamnesisPage";
import MyPatientsPage from "../pages/MyPatients/MyPatientsPage";
import AnthropometricPage from "../pages/MyPatients/Interations/Anthropometric/AnthropometricPage";
import DietPlanPage from "../pages/MyPatients/Interations/DietPlan/DietPlanPage";

const Routes: React.FC = () => {    
    return (
        <ReactDomRoutes>
            <Route path={ROUTES.MY_PATIENTS} element={<Private component={MyPatientsPage} />} />
            <Route path={ROUTES.PATIENT_DETAIL} element={<Private component={PatientDetailPage} />} />
            <Route path={ROUTES.PATIENT_NEW_ANAMNESIS} element={<Private component={AnamnesisPage} />} />
            <Route path={ROUTES.PATIENT_ANAMNESIS} element={<Private component={AnamnesisPage} />} />
            <Route path={ROUTES.PATIENT_NEW_ANTHROPOMETRIC} element={<Private component={AnthropometricPage} />} />
            <Route path={ROUTES.PATIENT_ANTHROPOMETRIC} element={<Private component={AnthropometricPage} />} />
            <Route path={ROUTES.PATIENT_NEW_DIET_PLAN} element={<Private component={DietPlanPage} />} />
            <Route path={ROUTES.PATIENT_NEW_DIET_PLAN_MANUAL} element={<Private component={DietPlanPage} />} />
            <Route path={ROUTES.PATIENT_NEW_DIET_PLAN_IMPORT} element={<Private component={DietPlanPage} />} />
        </ReactDomRoutes>
    );
};

export default Routes;