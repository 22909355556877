import { Clinic } from "../domain/interfaces/Clinic";
import api from "./api";


export default class ClinicService {
  
  public static getClinics(nutritionistId: string): Promise<Clinic[]> {
    return new Promise((resolve, reject) => {
      api.get(`/nutritionists/${nutritionistId}/clinics`)
        .then((response) => {
          if(response.status === 204) return [];
          
          const clinics = response.data.map((clinic: Clinic) => clinic) as Clinic[];
          resolve(clinics);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}



