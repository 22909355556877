import { ThemeOptions, createTheme } from "@mui/material/styles";

const modo = localStorage.getItem("modo") === "dark" ? "dark" : "light";

const themeOptions: ThemeOptions = {
  palette: {
    mode: modo,
    primary: {
      main: "#FF9800",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#8BC24B",
      light: "#8BC24B"
    },
    action: {
      hover: "#FFE0B2",
    },
    background: {
      default: modo === "dark" ? "#303030" : "#EBEBEB",
      paper: modo === "dark" ? "#424242" : "#ffffff",
    },
    text: {
      primary: modo === "dark" ? "#ffffff" : "#242424",
      secondary: modo === "dark" ? "#ffffff" : "#666",
      disabled: modo === "dark" ? "#e6e6e6" : "#ccc"
    },
    
    // borderRadius: 10,
  },
  typography: {
    fontFamily: 'Comfortaa, "Helvetica", "Arial", sans-serif',
    fontSize: 12,
  },
  components: {
    MuiDialog: {
      defaultProps: {
        onClick: (e) => e.stopPropagation(),
        BackdropProps: {
          sx: {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          alignItems: "center",
          borderRadius: 10,
          // paddingRight: 4,
          "& legend": {
            visibility: "visible"
          }
        },

      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Define bordas arredondadas
          "&:hover": {
          },
        },
        containedPrimary: {
          backgroundColor: "#FF9800",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#FF9800",
          },
        },
        containedSecondary: {
          backgroundColor: "#8BC24B",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#7CB342",
          },
        },
        outlinedPrimary: {
          borderColor: "#FF9800",
          color: "#FF9800",
          "&:hover": {
            borderColor: "#FB8C00",
          },
        },
      },
    },
  }
};




export default createTheme(themeOptions);