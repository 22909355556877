import React, { useState } from "react";
import { Box, Button, InputLabel, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { LayoutState } from "../../state/reducers/layout.reducer";


export interface AppSelectableProps {
    selectableValues: AppSelectableValue[],
    value?: string[],
    label?: string,
    onChanged?: (selectedValues: string[]) => void;
    responsive?: boolean;
}

interface AppSelectableValue {
    value: string;
    label: string;
    disabled?: boolean
}

interface AppSelectableItemProps {
    value: string,
    disabled? : boolean,
    selected: boolean,
    onClick?: ()=>void;
}
const AppSelectableItem: React.FC<AppSelectableItemProps> = ({value, disabled, selected, onClick}) => {


    let style: any = {
        borderRadius:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        minHeight: "32px",
        minWidth: "32px",
        border: "1px solid",
        borderColor: "secondary.main",
        color: "secondary.main",
        cursor: "pointer",
        transition: "all .3s",
        "&:hover": {
            backgroundColor: "rgba(67, 160, 71, 0.04)",
            borderColor: "secondary.main"
        }
    };

    if(selected)
    {
        style = {...style, 
            backgroundColor: "secondary.main",
            color:"#fff",
            "&:hover": {
                backgroundColor:"#699539",
                boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
            }
         }
    }

    if(disabled)
    {
        style = {...style, 
            backgroundColor: "#e6e6e6!important",
            color:"#aaa", 
            border: "none",
            pointerEvents:"none"
         }
    }

    return(
        <Box sx={style} onClick={onClick}>
            {value}
        </Box>
    );
}

const AppSelectable: React.FC<AppSelectableProps> = ({ label, value = [], selectableValues, onChanged, responsive = true }) => {
    const [selectedValues, setSelectedValues] = useState<string[]>(value);
    const layout = useSelector((state: any) => state.layout as LayoutState);

    const toggleItem = (value: string) => {
        setSelectedValues(prev => {
            var newSelectedValues = prev.includes(value)
                ? prev.filter(day => day !== value)
                : [...prev, value]

            if (onChanged)
                onChanged(newSelectedValues)

            return newSelectedValues;
        });
    };

    return (
        <Box>
            {label &&
                <InputLabel sx={{ color: "theme.secondary.main", fontWeight: "bold", mb: 1 }}>
                    {label}
                </InputLabel>
            }

            <Stack direction="row" spacing={1}>
                {selectableValues.map(sv => (
                    <>
                    {layout.windowSize.width > 1130 || !responsive ?
                        <Button
                            key={sv.value}
                            disabled={sv.disabled !== null ? sv.disabled : false}
                            size="small"
                            sx={{borderRadius:"8px"}}
                            variant={selectedValues.includes(sv.value) ? "contained" : "outlined"}
                            color={"secondary"}
                            onClick={() => toggleItem(sv.value)}
                        >
                            {layout.windowSize.width <= 1300 && responsive ? sv.label.substring(0,3) : sv.label}
                        </Button>
                    
                       : 
                        <AppSelectableItem
                            value={sv.label.substring(0,1)}
                            selected={selectedValues.includes(sv.value)}
                            disabled={sv.disabled !== null ? sv.disabled : false}
                            onClick={()=> toggleItem(sv.value)}
                        />
                    }
                    </>

                ))}
            </Stack>

        </Box>
    );
};

export default AppSelectable;