import React, { FC, useState } from "react";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import AppTextField from "../../../../../components/forms/AppTextField";
import { AppFoodDietPlan } from "../types";
import { ArrowBack, Search } from "@mui/icons-material";
import AppButton from "../../../../../components/forms/AppButton";
import AppSelectable from "../../../../../components/forms/AppSelectable";
import { useTheme } from "@mui/material/styles";
import AppSelect from "../../../../../components/forms/AppSelect";

interface FoodSelectionProps {

    onFoodSelected: (newFood: AppFoodDietPlan) => void;
    onArrowBackClicked: () => void;

}

const apiMock = {


    searchFood: (term: string, selectedTables: string[]): Promise<AppFoodDietPlan[]> => {

        const DELAY_MOCK = 500;
        const foodListMock = [
            {
                id: "001",
                table: "TBCA",
                name: "Porção de Arroz",
                portion: ""
            },
            {
                id: "002",
                table: "TBCA",
                name: "Banana Prata",
                portion: ""
            },
            {
                id: "003",
                table: "TBCA",
                name: "Bife Assado",
                portion: ""
            },
            {
                id: "004",
                table: "IBGE",
                name: "Feijão Mulatinho",
                portion: ""
            }
        ];

        return new Promise((resolve) => {
            setTimeout(() => {
                const responseMock = foodListMock
                    .filter(food =>
                        food.name.toLowerCase().includes(term.toLowerCase())
                        && selectedTables.includes(food.table)
                    );
                resolve(responseMock);
            }, DELAY_MOCK);
        })
    }

}


const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;

    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

interface Props {
    foods: AppFoodDietPlan[];
    onFoodSelected?: (selectedFood: AppFoodDietPlan) => void;
}

const FoodTable: React.FC<Props> = ({ foods, onFoodSelected }) => {
    const theme = useTheme();
    return (
        <Box sx={{ width: '100%', marginTop: 2, display: 'grid', gridTemplateColumns: '1fr 3fr', gap: 2 }}>
            <Box sx={{ display: 'contents' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Tabela Nutricional</Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Descrição Alimento</Typography>
            </Box>


            {foods.map((food, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'contents',
                        // borderTop: '1px solid #ccc',
                        cursor: 'pointer',
                        '&:hover': {
                            color: theme.palette.secondary.main,
                            backgroundColor: 'rgba(0, 0, 0, 0.05)!important', // Efeito hover
                        },
                    }}
                    onClick={() => onFoodSelected && onFoodSelected(food)}
                >
                    <Box sx={{ padding: 1 }}>{food.table}</Box>
                    <Box sx={{ padding: 1 }}>{food.name}</Box>
                </Box>

            ))}
        </Box>
    );
};


const FoodSelection: FC<FoodSelectionProps> = ({ onFoodSelected, onArrowBackClicked }) => {

    const [foods, setFoods] = useState<AppFoodDietPlan[]>([]);
    const [selectedTables, setSelectedTables] = useState<string[]>(["TBCA", "IBGE", "TACO"]);
    const [term, setTerm] = useState("");
    const [foodSelected, setFoodSelected] = useState<AppFoodDietPlan | null>(null);

    const search = async (term: string) => {
        if (term === "") {
            setFoods([]);
            return;
        }

        const response = await apiMock.searchFood(term, selectedTables);
        setFoods(response);
    }

    const debouncedSearch = debounce(search, 100);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTerm(event.target.value);
        debouncedSearch(event.target.value);
    };

    const handleSelectedTableSearch = (tables: string[]) => {
        setSelectedTables(tables);
        search(term);
    }

    const handleFoodSelected = (food: AppFoodDietPlan) => {
        // TODO: Gets the api for retrieve food portion details
        food.portionOptions = ["100 Gramas","4 Colheres"]
        setFoodSelected(food);
    }

    return (
        <Box sx={{ border: "1px solid #ccc", borderRadius: 4, p: 2 }}>
            <Grid sx={{ mb: 2 }} container spacing={1} justifyContent={"space-between"} alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box sx={{ "display": "flex", gap: 1, alignItems: "center" }}>
                        <IconButton sx={{ backgroundColor: "#f2f2f2" }} onClick={onArrowBackClicked}>
                            <ArrowBack/>
                        </IconButton>
                        <Box sx={{ width: "100%" }}>
                            <AppTextField
                                fullWidth
                                onChange={handleSearch}
                                startAdornment={<Search/>}
                                name={"searchFood"}
                                placeholder={"Pesquise por um alimento"}
                            />
                        </Box>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} justifyContent={"center"}>
                    <AppSelectable responsive={false} selectableValues={[

                        { value: "TBCA", label: "TBCA" },
                        { value: "IBGE", label: "IBGE" },
                        { value: "TACO", label: "TACO" },

                    ]}
                                   value={selectedTables}
                                   onChanged={handleSelectedTableSearch}
                    />
                </Grid>
            </Grid>

            <Divider/>

            {term === "" &&
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "150px"
                }}>
                    <Typography variant="h6" sx={{ color: "text.secondary" }} textAlign={"center"} component="div">
                        Digite o nome de um alimento para iniciar a busca
                    </Typography>
                </Box>
            }

            {( term !== "" && foods.length > 0 && !foodSelected ) &&

                <FoodTable foods={foods} onFoodSelected={handleFoodSelected}/>
            }

            {
                foodSelected &&
                <Box>
                    <Box sx={{ mt:2, display: "flex", alignItems: "center" }}>
                        <IconButton onClick={()=>{
                            setFoodSelected(null)
                        }}>
                            <ArrowBack/>
                        </IconButton>
                        <Typography variant="h6">{foodSelected.name}</Typography>
                    </Box>
                    <Box sx={{mt:2, display:"flex", gap:2}}>
                        <Box sx={{width:"300px"}}>
                            <AppSelect
                                label={"Selecione uma porção"}
                                fullWidth
                                options={foodSelected.portionOptions!.map(p => ({
                                    value: p,
                                    label: p
                                }))}
                                value={foodSelected.portion}
                                onChange={(event) => {
                                    setFoodSelected(f => (
                                        { ...f,
                                            portion: event.target.value
                                        } as AppFoodDietPlan
                                    ));
                                }}

                            />
                        </Box>

                        <AppButton
                            disabled={!foodSelected.portion}
                            variant={"contained"}
                            label={"Adicionar"}
                            color={"primary"}
                            onClick={()=> onFoodSelected(foodSelected)}
                        />
                    </Box>
                </Box>
            }

        </Box>
    );
}

export default FoodSelection;