import React, { useCallback } from 'react';
import { Box, IconButton, InputAdornment, InputLabel, OutlinedInput, OutlinedInputProps, SxProps, Theme, useTheme } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LabelType } from './enums/LabelType';
import AppInputError from './components/InputErro';
import AppTooltip from './AppTooltip';

interface FormTextFieldProps extends OutlinedInputProps{
    name: string,
    id?: string,
    label?: string,
    labelType?: LabelType,
    type?: string,
    errorMessage?: string; 
    boxSx?: SxProps<Theme>;
    tooltip?: string | JSX.Element;
    tooltipPosition?: "top" | "right" | "bottom" | "left" | undefined;
}

const AppTextField: React.FC<FormTextFieldProps> = (props) => {

    let { name, sx, label, labelType, errorMessage, type, boxSx, tooltip, tooltipPosition, disabled, ...rest } = props;
    const theme = useTheme();
    const [showPassword, setShowPassword] = React.useState(false);
    const labelColor = disabled ? theme.palette.text.disabled : theme.palette.text.primary;
  
    const handleClickShowPassword = useCallback(() => {
        setShowPassword((show) => !show);
    }, []);

    const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }, []);

    const hasError = errorMessage !== undefined && errorMessage !== null && errorMessage !== "";

    if(!labelType){
        labelType = LabelType.FLOATING;  
    }

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        rest.onChange && rest.onChange(e);
    }, [rest]);

    return (
        <Box sx={{ position: 'relative', ...boxSx}}>
            { labelType === LabelType.INLINE &&
                <Box sx={{display:"flex"}}>
                    <InputLabel sx={{ color: labelColor, fontWeight:"bold", wordWrap: "break-word",  whiteSpace: "normal" }}>
                        { label }
                    </InputLabel>
                    <AppTooltip
                        position={tooltipPosition}
                    >{tooltip}
                    </AppTooltip>
                </Box>
            }
            <OutlinedInput 
                size='small'
                notched
                disabled={disabled}
                label={(labelType === LabelType.FLOATING && label) ?
                    <InputLabel sx={{ position: "initial", color: labelColor, fontSize: "10px", fontWeight:"bold" }}>
                        {label}
                    </InputLabel>

                    : "" }
                onChange={handleChange}
                sx={{ 
                    borderRadius: "12px",
                    "svg > path": {
                        fill: "#4d4d4d" 
                    },
                    "input": {
                        width: "100%" 
                    },
                    ...sx,
                    color: labelColor
                }}
                {...rest}
                error = {hasError}
                type= {type && type === "password" ? (showPassword ? "text" : "password") : type}
                endAdornment={
                    type && type === "password" &&
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                }
            /> 
            <AppInputError error={errorMessage} />
        </Box>


    );
}

export default AppTextField;