import { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Mail } from '@mui/icons-material';
import moment from 'moment';
import AppTextField from '../../../components/forms/AppTextField';
import AppDatePicker from '../../../components/forms/AppDatePicker';
import AppRadioButton from '../../../components/forms/AppRadioButton';
import AppSlider from '../../../components/forms/AppSlider';
import AppSelect, { AppSelectOption } from '../../../components/forms/AppSelect';
import FormPatientBaseProps from './interfaces/FormPacienteBaseProps';
import { Gender } from '../../../domain/interfaces/Patient';
import { useAuth } from '../../../hooks/core/AuthProvider';
import ClinicService from '../../../services/clinic.service';
import AddressService from '../../../services/address.service';
import { MaritalStatus } from '../../../domain/enums/MaritalStatus';


const PatientBaseFields: FC<FormPatientBaseProps> = (props) => {
  const { patient, setPatient, formErrors, isEmailFieldDisabled } = props;
  const [clinicsOptions, setClinicsOptions] = useState([] as AppSelectOption[]);
  const { user } = useAuth();

  const handleChangeGender = (event: React.ChangeEvent<HTMLInputElement>) => {
    const gender = event.target.value === "1" ? Gender.Male : Gender.Female;
    setPatient({ ...patient, gender: gender })
    if (gender === Gender.Male) {
      setPatient(p => ({ ...p, isPregnant: false, numberOfPregnancyWeeks: 0 }))
    }
  }

  const handleChangePregnant = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isPregnant = event.target.value === "true";

    setPatient({ ...patient, isPregnant: isPregnant });

    if (!isPregnant) {
      setPatient(p => ({ ...p, numberOfPregnancyWeeks: 0 }))
    }
  }

  useEffect(() => {
    ClinicService.getClinics(user.id).then((clinics) => {
      let clinicOptions = clinics.map(clinic => {
        return { value: clinic.id, label: clinic.name } as AppSelectOption
      });
      clinicOptions = [...[{
        label: "Selecione o consultório de atendimento",
        value: null
      }],...clinicOptions];
      setClinicsOptions(clinicOptions);
    });

  }, [user.id]);

  useEffect(() => {
    const fetchAddress = async (postalCode: string) => {
      const addressData = await AddressService.getAddressByPostalCode(postalCode);

      if (addressData && patient.id === undefined) {
        setPatient({
          ...patient,
          address: {
            number: "",
            postalCode: postalCode,
            country: 'Brasil',
            city: addressData.localidade,
            state: addressData.uf,
            street: addressData.logradouro,
          }
        });
      }
    }
    // remove all non numeric characters
    const postalCode = patient.address?.postalCode !== undefined ? patient.address.postalCode.replace(/\D/g, '')! : "";
    if (postalCode.length === 8) {
      fetchAddress(postalCode);
    }


  }, [patient.address?.postalCode, setPatient]);


  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={9} xs={12}>
          <AppTextField
            fullWidth
            name="nome"
            type="text"
            autoFocus={true}
            label="Nome do paciente*"
            value={patient.name}
            onChange={(e) => setPatient({ ...patient, name: e.target.value })}
            errorMessage={formErrors.nome}
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <AppDatePicker
            fullWidth
            label="Data de Nascimento*"
            name="dataNascimento"
            value={moment(patient.birthDate)}
            onChange={(date) => setPatient({ ...patient, birthDate: date?.format("YYYY-MM-DD") })}
            errorMessage={formErrors.birthDate}
          />
        </Grid>

        <Grid item md={9} xs={12} >
          <AppTextField
            fullWidth
            name="email"
            value={patient.email}
            onChange={(e) => setPatient({ ...patient, email: e.target.value })}
            type="email"
            label="E-mail*"
            endAdornment={<Mail />}
            disabled={isEmailFieldDisabled ?? false}
            errorMessage={formErrors.email}
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <AppSelect
            fullWidth
            name="estadoCivil"
            label="Estado Civil"
            value={patient.maritalStatus}
            onChange={(e) => setPatient({ ...patient, maritalStatus: e.target.value })}
            options={[
              { value: "", label: "Selecione o estado civil" },
              { value: MaritalStatus.Single, label: "Solteiro(a)" },
              { value: MaritalStatus.Married, label: "Casado(a)" },
              { value: MaritalStatus.Separeted, label: "Divorciado(a)" },
              { value: MaritalStatus.Widowed, label: "Viúvo(a)" },
            ]}
            errorMessage={formErrors.maritalStatus}
          />
        </Grid>
     
        {/* Profession */}
        <Grid item md={6} xs={12}>
          <AppTextField
            fullWidth
            name="profissao"
            type="text"
            label="Profissão"
            value={patient.profession}
            onChange={(e) => setPatient({ ...patient, profession: e.target.value })}
            errorMessage={formErrors.profession}
          />
        </Grid>

        {/* Clinic */}
        <Grid item md={6} xs={12}>
          <AppSelect
            fullWidth
            name="clinic"
            label="Consultório de Atendimento"
            value={patient.clinicId}
            onChange={(e) => setPatient({ ...patient, clinicId: e.target.value })}
            options={clinicsOptions}
            errorMessage={formErrors.clinic}
          />
        </Grid>

      </Grid>

      <Grid container spacing={2} xs={12} md={12} sx={{ mb: 2, mt: 1 }}>
        {/* Sex */}
        <Grid item xs={3}>
          <AppRadioButton
            label="Sexo*"
            onChange={handleChangeGender}
            value={patient.gender}
            name="sexo"
            options={[{ label: "Feminino", value: Gender.Female }, { label: "Masculino", value: Gender.Male }]}
            errorMessage={formErrors.gender}
          />
        </Grid>

        {/* Is Pregnant */}
        {patient.gender === Gender.Female &&
          <Grid item md={3} xs={12}>
            <AppRadioButton
              label="Gestante?"
              onChange={handleChangePregnant}
              value={patient.isPregnant}
              name="gestante"
              options={[{ label: "Sim", value: true }, { label: "Não", value: false }]}
              errorMessage={formErrors.pregnant}
            />
          </Grid>
        }

        {/* Pregnancy Weeks */}
        {patient.isPregnant &&
          <Grid item md={4} xs={12}>
            <AppSlider
              name="semanaGestacao"
              label="Semana da Gestação"
              value={patient.numberOfPregnancyWeeks}
              onChange={(e, value) => setPatient({ ...patient, numberOfPregnancyWeeks: value as number })}
              min={1}
              max={42}
            />
          </Grid>
        }
      </Grid>
    </>);
}

export default PatientBaseFields;