import React, { FC, useCallback } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AppAcordion from "./AppAcordion";
import { Box } from "@mui/material";

interface AppCheckAccordionProps {
    complete?: boolean;
    title: string;
    children?: any;
}

const AppCheckAccordion: FC<AppCheckAccordionProps> = ({ complete, title, children }) => {

    const titleIcon = complete === true ? <CheckCircleIcon sx={{ mr: 1, mt:"2px", color:"green" }} /> : <RemoveCircleIcon sx={{ mr: 1, mt:"2px" }} />;

    const AppAcordionContainer = useCallback(({children}:{children:React.ReactElement<any>}) => {
        return (<Box sx={{pl:2, pr:2}}>{children}</Box>)
    }, []);

    return (

        <AppAcordion
            defaultExpanded={false}
            title={title}
            sx={{ borderTop: "1px solid #e0e0e0" }}
        // titleIcon={titleIcon}
        >
            <AppAcordionContainer>
                    {children}
            </AppAcordionContainer>
        </AppAcordion>
    );
}

export default AppCheckAccordion;