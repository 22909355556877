import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import AppButton from "../../../../../components/forms/AppButton";
import AddIcon from "@mui/icons-material/Add";

export const AddItemArea: FC<{
  title?: string;
  subtitle?: string;
  actionText: string;
  onClick?: () => void;
}> = ({ title, subtitle, actionText, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderColor: "#ccc",
        borderRadius: 4,
        p: 4,
        backgroundColor: "#f2f2f2"
      }}
    >
      {title && (
        <Typography sx={{ textAlign: "center" }} variant="h5">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }} variant="body1">
          {subtitle}
        </Typography>
      )}
      <AppButton
        onClick={onClick}
        startIcon={<AddIcon />}
        label={actionText}
        color="secondary"
        variant="contained"
        sx={{}}
      />
    </Box>
  );
};