import React from 'react';
import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import AppTextField from '../../../components/forms/AppTextField';
import FormPatientBaseProps from './interfaces/FormPacienteBaseProps';
import AppMaskedTextField from '../../../components/forms/AppMaskedTextField';
import AppSelect, { AppSelectOption } from '../../../components/forms/AppSelect';
import AddressService from '../../../services/address.service';

const PatientAddressFields: FC<FormPatientBaseProps> = (props) => {

  const { patient, setPatient } = props;
  const [cities, setCities] = React.useState([] as AppSelectOption[]);
  const [states, setStates] = React.useState([] as AppSelectOption[]);

  React.useEffect(() => {
    const getStates = async () => {
      if (!patient.address?.country) return;
      const states = await AddressService.getStatesByCountry(patient.address!.country);

      const statesOptions = states.map((state) => {
        return { value: state, label: state } as AppSelectOption;
      });

      setStates(statesOptions);
    }

    if (patient.address?.country) {
      getStates();
    }

  }, [patient.address?.country]);


  React.useEffect(() => {
    const getCities = async () => {
      if (!patient.address?.state) return;
      const cities = await AddressService.getCitiesByState(patient.address!.state);

      const citiesOptions = cities.map((city) => {
        return { value: city, label: city } as AppSelectOption;
      });

      setCities(citiesOptions);
    }

    if (patient.address?.state) {
      getCities();
    }

  }, [patient.address?.state]);

  return (
    <Grid container >
      <Typography variant="h6" sx={{ mt: 2, mb: 2, textAlign: "left" }}>Endereço</Typography>
      <Grid container spacing={2}>
        {/* Postal Code */}
        <Grid item md={4} xs={12}>
          <AppMaskedTextField
            fullWidth
            name="postalCode"
            type="text"
            label="CEP"
            value={patient.address?.postalCode}
            mask="99999-999"
            onChange={(e) => setPatient({ ...patient, address: { ...patient.address, postalCode: e.target.value } })}
          />
        </Grid>

        {/* Country */}
        <Grid item md={4} xs={12}>
          <AppTextField
            fullWidth
            name="country"
            type="text"
            boxSx={{ flex: 1 }}
            label="País"
            disabled={true}
            value={patient.address?.country}
            onChange={(e) => setPatient({ ...patient, address: { ...patient.address, country: e.target.value } })}
          />
        </Grid>

        {/* State */}
        <Grid item md={4} xs={12}>
          <AppSelect
            fullWidth
            name="state"
            label="Estado"
            boxSx={{ flex: 1 }}
            value={patient.address?.state}
            onChange={(e) => setPatient({ ...patient, address: { ...patient.address, state: e.target.value } })}
            options={states}
          />
        </Grid>

        {/* City */}
        <Grid item md={6} xs={12}>
          <AppSelect
            fullWidth
            name="city"
            label="Cidade"
            boxSx={{ flex: 2 }}
            value={patient.address?.city}
            onChange={(e) => setPatient({ ...patient, address: { ...patient.address, city: e.target.value } })}
            options={cities}
          />
        </Grid>

        {/* Street */}
        <Grid item md={6} xs={12}>
          <AppTextField
            fullWidth
            name="street"
            type="text"
            boxSx={{ flex: 2 }}
            label="Endereço"
            value={patient.address?.street}
            onChange={(e) => setPatient({ ...patient, address: { ...patient.address, street: e.target.value } })}
          />
        </Grid>

        {/* Number */}
        <Grid item md={4} xs={12}>
          <AppTextField
            fullWidth
            name="number"
            type="text"
            boxSx={{ flex: 1 }}
            label="Número"
            value={patient.address?.number}
            onChange={(e) => setPatient({ ...patient, address: { ...patient.address, number: e.target.value } })}
          />
        </Grid>

        {/* Complement */}
        <Grid item md={8} xs={12}>
          <AppTextField
            fullWidth
            label="Complemento"
            name="complemento"
            type="text"
            boxSx={{ flex: 2 }}
            value={patient.address?.complement}
            onChange={(e) => setPatient({ ...patient, address: { ...patient.address, complement: e.target.value } })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PatientAddressFields;