import React from 'react'; 
import { useParams, useNavigate } from "react-router-dom";
import AppMainLayout from '../../components/layout/AppMainLayout';
import AppMainHeader from '../../components/layout/AppMainHeader';
import AppCard from '../../components/layout/AppCard';
import { Divider  } from '@mui/material';
import { ROUTE_ACTIONS } from '../../constants/routes';
import AppDetailPageHeader from '../../components/layout/AppDetailPageHeader';
import ClinicForm from './components/ClinicForm';
import { Clinic } from '../../domain/interfaces/Clinic';
import api from '../../services/api';
import { useAuth } from '../../hooks/core/AuthProvider';
import { useAppToast } from '../../hooks/core/AppToastContextProvider';
import AppLoading from '../../components/utils/AppLoading';

const ClinicDetailPage: React.FC = () => {

  const { id, action } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { addToast } = useAppToast();

  const [clinic, setClinic] = React.useState(null as Clinic | null) ;
  const [ isProcessing, setIsProcessing ] = React.useState(false);

  const getClinic = async () => {
    const response = await api.get(`/nutritionists/${user.id}/clinics/${id}`);

    if(response.status === 200){
      setClinic(response.data as Clinic);
      return;
    }

    // handle error
    addToast({title: "Erro", description: "Erro ao buscar dados do consultório", type: "error"});
  }

  const submitClinic = async (clinic: Clinic) => {
    setIsProcessing(true);
    
    const response = await api.put(`/nutritionists/${user.id}/clinics/${id}`, clinic);
    
    setIsProcessing(false);
    
    if(response.status === 200){
      addToast({title: "Sucesso", description: "Consultório atualizado com sucesso", type: "success"});
      navigate("/meus-consultorios");
      return;
    }


  }

  React.useEffect(()=>{
    getClinic();
  }, [user.id, id]);



  return (
    <AppMainLayout>
      <AppMainHeader hasBreadCrumbs={true} />
      <AppLoading isLoading={isProcessing} />
      
      <AppCard>
        <AppDetailPageHeader title="Detalhes Consultório" action={action} editRoute={`/meus-consultorios/${id}/${ROUTE_ACTIONS.EDIT}`} />
      
        <Divider orientation="horizontal" sx={{margin:" 16px 0"}}></Divider>
        {clinic != null &&
          <ClinicForm initialClinicData={clinic} isDisabled={isProcessing || action === ROUTE_ACTIONS.VIEW} onSubmit={submitClinic} />
        }
      </AppCard>

    </AppMainLayout>
  );
}

export default ClinicDetailPage;