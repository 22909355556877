import { Patient } from "../domain/interfaces/Patient";
import api from "./api";

export interface GetPatientsByEmailOrName {
    name: string,
    email: string,
    nutritionistId: string,
    isOnlyActive: boolean,
    rowsPerPage: number,
    offset: number
}


export default class PatientService {

    public static getPatientsByEmailOrName(getPatientsByEmailOrName: GetPatientsByEmailOrName): Promise<any> {
        return new Promise((resolve, reject) => {
            const { name, nutritionistId: nutritionistId, email, isOnlyActive, rowsPerPage, offset } = getPatientsByEmailOrName;

            let url = `/nutritionists/${nutritionistId}/patients?Name=${name}&Email=${email}&Limit=${rowsPerPage}&Offset=${offset}`;

            if(isOnlyActive) url += `&isActive=${isOnlyActive}`;

            api.get(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    public static patientUpdate(patient: Patient, nutritionistId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            api.put(`/nutritionists/${nutritionistId}/patients/${patient.id}`, patient)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}



