import * as React from "react";
import { Box, Divider, Paper, Typography, useTheme } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import AppSearchIcon from "../icons/AppSearchIcon";
import logo from "../../assets/img/nutrifit-logo.png";
import { useSelector } from "react-redux";
import AppOfficeIcon from "../icons/AppOfficeIcon";
import AppPeopleIcon from "../icons/AppPeopleIcon";
import MenuItem from "./AppMenuItem";
import { LayoutState } from "../../state/reducers/layout.reducer";
import { usePatientStore } from "../../pages/MyPatients/Stores/PatientStore/PatientStore";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import AppManageAccountIcon from "../icons/AppManageAccountIcon";
import ExitToApp from "@mui/icons-material/ExitToApp";
import { useAuth } from "../../hooks/core/AuthProvider";

const ProfileMenuItem = ({
  icon,
  text,
  onClick,
}: {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
}) => {
  return (
    <Typography
      sx={{
        pl: 2,
        mt: 2,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: 1,
        transition: ".3s",
        ":hover": {
          color: "#444",
        },
      }}
      component="p"
      onClick={onClick}
    >
      {icon}
      {text}
    </Typography>
  );
};

const AppTopBar: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [searchActive, setSearchActive] = React.useState(false);
  const [sideMenuActive, setSideMenuActive] = React.useState(false);
  const layout = useSelector((state: any) => state.layout as LayoutState);

  const topBarStyle = {
    position: "sticky",
    zIndex: 2,
    width: "100vw",
    top: 0,
    height: "64px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
    display: layout.windowSize.isMobile ? "block" : "none",
  };
  const mainContainerStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    pt: 2,
    pb: 2,
    position: "relative",
  };
  const logoStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const sideMenuStyle = {
    position: "fixed",
    right: 0,
    top: 0,
    marginTop: "64px",
    backgroundColor: "#fff",
    zIndex: sideMenuActive ? 3 : -100,
    width: "200px",
    borderTop: "1px solid #e6e6e6",
    opacity: sideMenuActive ? 1 : 0,
    minHeight: "calc(100vh - 64px)",
    boxShadow: "0 5px 5px #ccc",
    transition: "opacity .3s ease-in-out",
  };

  React.useEffect(() => {
    setSideMenuActive(false);
  }, [layout.windowSize]);

  React.useEffect(() => {
    if (sideMenuActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [sideMenuActive]);

  return (
    <>
      <Paper sx={topBarStyle}>
        <Box sx={mainContainerStyle}>
          <Box sx={logoStyle}>
            <img
              src={logo}
              alt="logo"
              style={{ width: "120px", marginLeft: "32px", display: "block" }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 2,
              width: "70%",
            }}
          >
            <Box>
              <AppSearchIcon
                sx={{ fontSize: "18px", color: "#4d4d4d", cursor: "pointer" }}
                onClick={() => setSearchActive(true)}
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box onClick={() => setSideMenuActive(!sideMenuActive)}>
              {!sideMenuActive && (
                <MenuOpenIcon
                  sx={{
                    fontSize: "32px",
                    color: "#4d4d4d",
                    "&:hover": { color: theme.palette.secondary.main },
                    cursor: "pointer",
                    marginRight: "16px",
                  }}
                />
              )}

              {sideMenuActive && (
                <CloseIcon
                  sx={{
                    fontSize: "32px",
                    color: "#4d4d4d",
                    "&:hover": { color: theme.palette.secondary.main },
                    cursor: "pointer",
                    marginRight: "16px",
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      <Box sx={sideMenuStyle}>
        <Box sx={{  }}>
          <Box sx={{ display: "flex", pl: 2, pr: 2, pt: 2 }}>
            <Typography variant={"h6"}>{user.name}</Typography>
          </Box>
          <Box sx={{ m: 2 }}></Box>
          <MenuItem
            icon={<AppManageAccountIcon />}
            title="Meu Perfil"
            link={ROUTES.MY_PROFILE}
            border={"left"}
          />
          <ProfileMenuItem icon={<ExitToApp />} text="Sair" onClick={logout} />
        </Box>
        <Box sx={{ p: 2 }}>
          <Divider orientation="horizontal" flexItem />
        </Box>
        <Box>
          <MenuItem
            border={"left"}
            icon={<AppOfficeIcon />}
            link="/meus-consultorios"
            title="Meus Consultórios"
          />

          <MenuItem
            border={"left"}
            icon={<AppPeopleIcon />}
            link="/meus-pacientes"
            title="Meus Pacientes"
          />
        </Box>
      </Box>
    </>
  );
};

export default AppTopBar;
