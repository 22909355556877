import {FC, useCallback} from "react";
import {Alert, Grid} from "@mui/material";
import AppCheckAccordion from "../../../../components/layout/AppCheckAccordion";
import {SectionProps} from "./interfaces/SectionProps";
import AppFormTitleDivider from "../../components/AppFormTitleDivider";
import AppTextField from "../../../../components/forms/AppTextField";
import {LabelType} from "../../../../components/forms/enums/LabelType";
import AppRadioButton from "../../../../components/forms/AppRadioButton";
import AppStethoscopeIcon from "../../../../components/icons/AppStethoscopeIcon";
import AppRichSelect from "../../../../components/forms/AppRichSelect";
import HydrationTable from "./components/HydrationTable";
import AppDatePicker from "../../../../components/forms/AppDatePicker";
import {useAnamnesisStore} from "../../Stores/AnamnesisStore/AnamnesisStore";

import {Woman} from "@mui/icons-material";
import AppToiletIcon from "../../../../components/icons/AppToiletIcon";

import bistrolType1 from "../../../../assets/bistrol-stool-scale/type1.png";
import bistrolType2 from "../../../../assets/bistrol-stool-scale/type2.png";
import bistrolType3 from "../../../../assets/bistrol-stool-scale/type3.png";
import bistrolType4 from "../../../../assets/bistrol-stool-scale/type4.png";
import bistrolType5 from "../../../../assets/bistrol-stool-scale/type5.png";
import bistrolType6 from "../../../../assets/bistrol-stool-scale/type6.png";
import bistrolType7 from "../../../../assets/bistrol-stool-scale/type7.png";
import {ClinicEvaluationData, StoolClassificationResult} from "../../Stores/AnamnesisStore/types.d";
import {usePatientStore} from "../../Stores/PatientStore/PatientStore";
import {Gender} from "../../../../domain/interfaces/Patient";


type RefStoolClassificationResult = {
  [key: string]: StoolClassificationResult
}

const refStoolTypeClassificationResult: RefStoolClassificationResult = {
  "1": { value: "Sinal de constipação severa.", severity: "error" },
  "2": { value: "Sinal de constipação leve e de trânsito digestivo lento.", severity: "warning" },
  "3": { value: "Evacuação normal, sinal de ótimo trânsito intestinal.", severity: "success" },
  "4": { value: "Evacuação normal, sinal de ótimo trânsito intestinal.", severity: "success" },
  "5": { value: "Tendência à diarreia.", severity: "warning" },
  "6": { value: "Diarreia, sinal de trânsito intestinal desregulado.", severity: "error" },
  "7": { value: "Diarreia, sinal de trânsito intestinal desregulado.", severity: "error" }
};

const refStoolColorClassificationResult: RefStoolClassificationResult = {
  "Marrom": { value: "Trânsito intestinal adequado.", severity: "success" },
  "Amarelo": { value: "Sinal de excesso de gordura em fezes, podendo indicar problemas no fígado ou pâncreas.", severity: "warning" },
  "Verde": { value: "Sinal de consumo em excesso de vegetais verdes, estresse ou síndrome do intestino irritável.", severity: "warning" },
  "Avermelhado": { value: "Sinal de sangramento, podendo indicar hemorroidas, infecções ou problemas inflamatórios (como a doença de Crohn ou a colite ulcerativa).", severity: "warning" },
  "Escura": { value: "Sinal de suplementação de ferro ou de sangramento ao longo do sistema digestivo (estômago ou intestino delgado, por exemplo).", severity: "warning" },
  "Clara": { value: " Sinal de problemas no fígado ou na vesícula biliar.", severity: "warning" },
};

const getStoolTypeClassification = (id: string): StoolClassificationResult | null => {

  if (!refStoolTypeClassificationResult[id]) return null;
  return refStoolTypeClassificationResult[id];
}

const getStoolColorClassification = (color: string): StoolClassificationResult | null => {

  if (!refStoolColorClassificationResult[color]) return null;
  return refStoolColorClassificationResult[color];
}

const ClinicEvaluationSection: FC<SectionProps> = ({ complete }) => {



  const store = useAnamnesisStore(state => state.data.clinicEvaluation);
  const { patient } = usePatientStore();


  const setStateData = useAnamnesisStore(state => state.setData);


  const data = store;

  const setData = useCallback((fieldName: keyof ClinicEvaluationData, value: any) => {
    setStateData("clinicEvaluation", { ...data, [fieldName]: value });
  }, [data, setStateData]);


  return (
    <AppCheckAccordion title="Avaliação Clínica" complete={complete}>

      {/* --------------------------   Intestinal & urine Habits  --------------------------- */}
      <AppFormTitleDivider text="Hábito Intestinal e Urinário" icon={AppToiletIcon} iconColor={"#79dcfc"} />
      <Grid container spacing={4} sx={{ mb: 4, p: 1 }}>

        <Grid item xs={12} sm={6} md={3}>
          <AppRadioButton 
          compact={true} 
          boldLabel={true} 
          label="Modo" 
          name="intestinalHabitMode"
            options={[
              { label: "Normal", value: "Normal" },
              { label: "Constipante", value: "Constipante" },
              { label: "Diarréico", value: "Diarréico" },
              { label: "Misto", value: "Misto" },
            ]}
            value={data.intestinalHabitMode}
            onChange={e => setData("intestinalHabitMode", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppTextField
            name="evacuationFrequency"
            type="text"
            label="Qual é a frequência de evacuação?"
            value={data.evacuationFrequency}
            labelType={LabelType.INLINE}
            onChange={e => setData("evacuationFrequency", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppRadioButton 
          compact={true} 
          boldLabel={true} 
          label="Faz uso de laxante?" 
          name="useLaxative"
          value={data.useLaxative}
          onChange={e => setData("useLaxative", e.target.value)}
          options={[
            { label: "Sim", value: "Sim" },
            { label: "Não", value: "Não" },
            { label: "Eventualmente", value: "Eventualmente" }
          ]}

          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <AppRichSelect
            tooltip={<span>Segundo a <b>Escala de Bristol</b>, qual é o tipo de fezes evacuadas pelo paciente?</span>}
            label="Classificação das Fezes"
            options={[
              { id: 1, title: "Tipo 1", description: "Caroços duros e separados, como nozes.", srcImg: bistrolType1 },
              { id: 2, title: "Tipo 2", description: "Irregulares, segmentados em forma de salsicha.", srcImg: bistrolType2 },
              { id: 3, title: "Tipo 3", description: "Forma de salsicha, mas com rachaduras na superfície.", srcImg: bistrolType3 },
              { id: 4, title: "Tipo 4", description: "Alongadas com forma de salsicha ou cobra lisa.", srcImg: bistrolType4 },
              { id: 5, title: "Tipo 5", description: "Pedaços separados, macios com bordas nítidas.", srcImg: bistrolType5 },
              { id: 6, title: "Tipo 6", description: "Pedaços moles com bordas mal definidas.", srcImg: bistrolType6 },
              { id: 7, title: "Tipo 7", description: "Líquida, sem pedaços sólidos.", srcImg: bistrolType7 }
            ]}
            value={data.stoolType}
            onSelected={
              (stoolType) => {
                setStateData("clinicEvaluation", { 
                  ...data, 
                  stoolType: stoolType,
                  stoolTypeClassification: getStoolTypeClassification(stoolType)?.value,
                  stoolTypeSeverity: getStoolTypeClassification(stoolType)?.severity
                });
              }
            }

          />
          {
            data.stoolTypeClassification &&
            <Alert sx={{ mt: 1 }} severity={data.stoolTypeSeverity as any}>
              {data.stoolTypeClassification}
            </Alert>
          }
        </Grid>



        <Grid item xs={12} sm={12} md={12}>
          <AppRadioButton
              orientation="row"
              compact={true}
              boldLabel={true}
              label="Classificação da Cor das Fezes"
              name="intestinal-habit-stool_color"
            options={[
              { label: "Marrom", value: "Marrom" },
              { label: "Amarelo", value: "Amarelo" },
              { label: "Verde", value: "Verde" },
              { label: "Avermelhado", value: "Avermelhado" },
              { label: "Escura", value: "Escura" },
              { label: "Clara", value: "Clara" },
            ]}
            value={data.stoolColor}
            onChange={(e) => {
              const value = e.target.value;
              setStateData("clinicEvaluation", { 
                ...data, 
                stoolColor: value, 
                stoolColorClassification: getStoolColorClassification(value)?.value,
                stoolColorSeverity:  getStoolColorClassification(value)?.severity,
              });
            }}
          />

          {
            data.stoolColorClassification &&
            <Alert sx={{ mt: 1 }} severity={data.stoolColorSeverity as any}>
              {data.stoolColorClassification}
            </Alert>
          }
        </Grid>



        <Grid item xs={12} sm={12} md={12}>
          <AppRadioButton
            tooltip={
              <HydrationTable />
            }
            orientation="row"
            compact={true}
            boldLabel={true}
            label="Classificação da Urina"
            name="urineColor"
            options={[
              { label: "Bem Hidratado", value: "Bem Hidratado" },
              { label: "Mal Hidratado", value: "Mal Hidratado" },
              { label: "Desidratado", value: "Desidratado" },
            ]}
            value={data.urineColor}
            onChange={e => setData("urineColor", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <AppTextField
            fullWidth
            name="intestinalHabitNotes"
            label="Hábitos Intestinais e Urinários"
            labelType={LabelType.INLINE}
            placeholder="Observações gerais sobre hábitos intestinais e urinários."
            multiline
            rows={4}
            value={data.intestinalHabitNotes}
            onChange={e => setData("intestinalHabitNotes", e.target.value)}
          />
        </Grid>
      </Grid>

      {/* --------------------------   Woman  --------------------------- */}
        {patient.gender === Gender.Female && <>
          <AppFormTitleDivider text="Mulheres" icon={Woman} iconColor={"#ff80ff"} />
          <Grid container spacing={4} sx={{ mb: 4, p: 1 }}>
            <Grid item xs={12} sm={6} md={3}>
              <AppRadioButton
                orientation="row"
                compact={true}
                boldLabel={true}
                label="Ciclo menstrual normal?"
                name="womanNormalMenstrualCycle"
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                value={data.womanNormalMenstrualCycle}
                onChange={e => setData("womanNormalMenstrualCycle", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AppRadioButton
                orientation="row"
                compact={true}
                boldLabel={true}
                label="Classificação TPM"
                name="tpmClassification"
                options={[
                  { label: "Fraca", value: "Fraca" },
                  { label: "Média", value: "Média" },
                  { label: "Forte", value: "Forte" },
                ]}
                value={data.tpmClassification}
                onChange={e => setData("tpmClassification", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AppDatePicker
                label="Última menstruação"
                name="lastMenstruationDate"
                labelType={LabelType.INLINE}
                value={data.lastMenstruationDate}
                onChange={(date) => {
                  setData("lastMenstruationDate", date);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <AppTextField
                fullWidth
                name="contraceptive"
                label="Contraceptivo"
                placeholder="Qual método contraceptivo está utilizando? / Utiliza há quanto tempo? / Utiliza corretamente? / Faz pausas no uso?"
                multiline
                rows={4}
                labelType={LabelType.INLINE}
                value={data.contraceptive}
                onChange={e => setData("contraceptive", e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppRadioButton orientation="row" compact={true} boldLabel={true} label="Tem cólicas?" name="hasColics"
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                value={data.hasColics}
                onChange={e => setData("hasColics", e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppRadioButton
                orientation="row"
                compact={true}
                boldLabel={true}
                label="É lactante?"
                name="isLactating"
                options={[
                  { label: "Sim", value: "Sim" },
                  { label: "Não", value: "Não" },
                ]}
                value={data.isLactating}
                onChange={e => setData("isLactating", e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppRadioButton
                orientation="row"
                compact={true}
                boldLabel={true}
                label="Está em menopausa?"
                name="isMenopause"
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                value={data.isMenopause}
                onChange={e => setData("isMenopause", e.target.value)}
              />
            </Grid>

          </Grid>
      </>}
      {/* --------------------------   Nutricional Semiology  --------------------------- */}
      <AppFormTitleDivider text="Semiologia Nutricional" tooltip={"A semiologia nutricional é um método de exame que ajuda a identificar sinais e sintomas de carência ou excesso de nutrientes."} icon={AppStethoscopeIcon} iconColor={"#5ba35e"} />
      <Grid container spacing={2} sx={{ mb: 4, p: 1 }}>
        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            value={data.hairNotes}
            onChange={e => setData("hairNotes", e.target.value)}
            name="hairNotes"
            label="Cabelo"
            labelType={LabelType.INLINE}
            multiline rows={4}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            value={data.faceNotes}
            onChange={e => setData("faceNotes", e.target.value)}
            name="faceNotes"
            label="Face"
            labelType={LabelType.INLINE}
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            value={data.eyesNotes}
            onChange={e => setData("eyesNotes", e.target.value)}
            name="eyesNotes"
            label="Olhos"
            labelType={LabelType.INLINE}
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            value={data.lipsNotes}
            onChange={e => setData("lipsNotes", e.target.value)}
            name="lipsNotes"
            label="Lábios"
            labelType={LabelType.INLINE}
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            value={data.gumsNotes}
            onChange={e => setData("gumsNotes", e.target.value)}
            name="gumsNotes"
            label="Gengiva"
            labelType={LabelType.INLINE}
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            value={data.nailsNotes}
            onChange={e => setData("nailsNotes", e.target.value)}
            name="nutricionalsemiology-nailsnotes"
            label="Unhas"
            labelType={LabelType.INLINE}
            multiline
            rows={4}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AppTextField
            fullWidth
            value={data.dentitionNotes}
            onChange={e => setData("dentitionNotes", e.target.value)}
            name="nutricionalsemiology-dentitionnnotes"
            label="Peças Dentárias"
            labelType={LabelType.INLINE}
            multiline
            rows={4}
          />
        </Grid>
      </Grid>

    </AppCheckAccordion>
  );

}

export default ClinicEvaluationSection;